import { useState, useEffect } from 'react';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import { checkPermission } from '~/src/global/services/permissionService';
import usePermissionsStore from './usePermissionsStore';

const usePermissionCheck = (permissionKey: string) => {
  const { permissions, setPermission } = usePermissionsStore();
  const storedPermission = permissions[permissionKey];

  const { accessToken } = useAccessToken();
  const [hasPermission, setHasPermission] = useState<boolean | null>(false);

  useEffect(() => {
    if (typeof storedPermission === 'boolean') {
      return;
    }

    const fetchPermissions = async () => {
      if (accessToken) {
        const permissionGranted = await checkPermission(
          accessToken,
          permissionKey,
          setPermission
        );
        setHasPermission(permissionGranted);
      }
    };
    fetchPermissions();
  }, [accessToken, permissionKey, setPermission, storedPermission]);

  if (typeof storedPermission === 'boolean') {
    return storedPermission;
  }

  return hasPermission;
};

export default usePermissionCheck;
