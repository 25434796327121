import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { MobileDataConfig } from '~/src/constants';
const { NATIONAL_RANKING_BAR_FILL_COLOR } = MobileDataConfig;

interface RankingProgressBarProps {
  value: number;
  title: string;
}

const getColor = (value: number): string => {
  if (value <= 20) {
    return NATIONAL_RANKING_BAR_FILL_COLOR.red; // Red
  } else if (value <= 40) {
    return NATIONAL_RANKING_BAR_FILL_COLOR.orange; // Orange
  } else if (value <= 60) {
    return NATIONAL_RANKING_BAR_FILL_COLOR.yellow; // Yellow
  } else if (value <= 80) {
    return NATIONAL_RANKING_BAR_FILL_COLOR.lightGreen; // Light Green
  } else {
    return NATIONAL_RANKING_BAR_FILL_COLOR.green; // Green
  }
};

const RankingProgressBar: React.FC<RankingProgressBarProps> = ({
  value,
  title,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginTop: 1,
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '60%' }}>
        <LinearProgress
          variant="determinate"
          value={Math.round(value)}
          sx={{
            height: 10,
            borderRadius: 5,
            width: '100%',
            backgroundColor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getColor(value), // Custom progress color
            },
          }}
        />

        <Typography
          variant="h5"
          sx={{
            marginLeft: 2,
            fontWeight: 'bold',
            fontSize: MobileDataConfig.MOBILE_RANKING_PERCENTAGE_FONT_SIZE,
          }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
      <Typography
        fontSize={MobileDataConfig.MOBILE_RANKING_PERCENTAGE_LABEL_FONT_SIZE}
        sx={{ color: 'gray', whiteSpace: 'nowrap' }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default RankingProgressBar;
