import { Feature, Polygon } from 'geojson';
import { CustomTerritory, TerritoryType } from '@plotr/plotr-multiplayer-data';
import { getColor } from '../menu/GlobalTerritorySettingsMenu';
import tinycolor from 'tinycolor2';
import { Rule } from '@plotr/plotr-multiplayer-data/src/lib/rulesets';

export const isPolygon = (feature: Feature): feature is Feature<Polygon> => {
  return feature.geometry.type === 'Polygon';
};

export const mapCustomTerritoriesToFeatures = ({
  customTerritories,
  rulesets,
}: {
  customTerritories: CustomTerritory[];
  rulesets: Rule[];
}) => {
  return customTerritories.reduce(
    (acc: Feature<Polygon>[], territory, index) => {
      if (territory.type === TerritoryType.Custom && territory.boundaries) {
        Object.values(territory.boundaries).forEach(
          (boundary, boundaryIndex) => {
            if (boundary.coordinates) {
              const color = getColor(rulesets, {
                ...territory.keyValuePairs,
                tags: territory.tags,
              });
              const hoverColor = tinycolor(color).darken(20).toString();
              const feature: Feature<Polygon> = {
                type: 'Feature',
                id: `${index}${Date.now().toString()}${boundaryIndex}`,
                properties: {
                  ...territory.keyValuePairs,
                  territoryId: territory.id,
                  label: `${territory.label} - Custom`,
                  tags: territory.tags,
                  color,
                  hoverColor,
                  group: territory.group,
                  boundary: boundary,
                },
                geometry: {
                  type: 'Polygon',
                  coordinates: [boundary.coordinates],
                },
              };
              acc.push(feature);
            }
          }
        );
      }
      return acc;
    },
    []
  );
};
