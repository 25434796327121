/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useState, ReactNode } from 'react';

export enum ModalNames {
  CREATE_TEMPLATE = 'createTemplateModal',
  SELECT_TEMPLATE_FOR_PIN = 'selectTemplateForPinModal',
}

type ModalContextType = {
  open: {
    [key in ModalNames]: boolean;
  };
  onOpenChange: (name: ModalNames, value: boolean) => void;
  data: {
    [key in ModalNames]: any;
  };
  onDataChange: (name: ModalNames, data: any) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<any>({
    createTemplateModal: false,
    selectTemplateForPinModal: false,
  });
  const [data, setData] = useState<any>({
    createTemplateModal: null,
    selectTemplateForPinModal: null,
  });

  const onOpenChange = (name: ModalNames, value: boolean) =>
    setOpen({ ...open, [name]: value });

  const onDataChange = (name: ModalNames, data: any) =>
    setData({ ...data, [name]: data });

  return (
    <ModalContext.Provider value={{ open, onOpenChange, data, onDataChange }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalDataContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalDataContext must be used within a ModalProvider');
  }
  return context;
};
