import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  plotrMultiplayerData,
  Rule,
  SourceType,
  TargetType,
} from '@plotr/plotr-multiplayer-data/src';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FullWidthColorPicker } from '~/src/common/components/CustomColorPicker';
import {
  AddKeyRuleSchema,
  AddKeyRuleSchemaType,
  AddTagRuleSchema,
  AddTagRuleSchemaType,
} from '~/src/validation/rules.schema';

type AddRuleFormProps = {
  closeForm: () => void;
  targetType: TargetType;
  editingRule?: Rule;
};

const AddRuleForm = ({
  closeForm,
  targetType,
  editingRule,
}: AddRuleFormProps) => {
  const [selectedColor, setSelectedColor] = useState(
    editingRule?.effect ?? '#ff0000'
  );
  const rulesetsMethods = plotrMultiplayerData.methods?.rulesets;
  const [showPropertyKeyInput, setShowPropertyKeyInput] = useState(
    editingRule?.propertyKey ?? false
  );
  const [showRuleValueInput, setShowRuleValueInput] = useState(
    editingRule?.evaluation.value ?? false
  );

  const defaultValues = editingRule
    ? {
        ruleType: editingRule.sourceType,
        ruleValue: editingRule.evaluation.value.toString(),
        ruleKeyValue: editingRule.propertyKey,
      }
    : { ruleType: '' as SourceType, ruleValue: '', ruleKeyValue: '' };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddKeyRuleSchemaType | AddTagRuleSchemaType>({
    resolver: zodResolver(
      showPropertyKeyInput ? AddKeyRuleSchema : AddTagRuleSchema
    ),
    defaultValues,
  });

  const handleSubmitRule: SubmitHandler<
    AddKeyRuleSchemaType | AddTagRuleSchemaType
  > = (data: AddKeyRuleSchemaType | AddTagRuleSchemaType) => {
    const propertyKey =
      data.ruleType === SourceType.KeyValue ? data.ruleKeyValue : undefined;
    const value =
      data.ruleType === SourceType.Tag
        ? data.ruleValue.toString().toLocaleLowerCase()
        : data.ruleValue;

    if (editingRule) {
      const updatedValues = {
        ...editingRule,
        sourceType: data.ruleType,
        effect: selectedColor,
        propertyKey,
        evaluation: {
          ...editingRule.evaluation,
          value,
        },
      };

      rulesetsMethods?.updateRule(editingRule.id, updatedValues);
    } else {
      const newRule: Rule = {
        id: `${crypto.randomUUID()}`,
        targetType,
        sourceType: data.ruleType,
        evaluation: {
          operator: '=',
          value,
        },
        effect: selectedColor,
        propertyKey,
      };

      rulesetsMethods?.addRule(newRule);
    }

    setShowPropertyKeyInput(false);
    setShowRuleValueInput(false);
    closeForm();
  };

  return (
    <Box display="flex" flexDirection={'column'} gap={1}>
      <Box component={'form'} onSubmit={handleSubmit(handleSubmitRule)}>
        <Controller
          control={control}
          name="ruleType"
          render={({ field: { onChange, ...props } }) => (
            <Select
              {...props}
              sx={{ marginBottom: 1 }}
              onChange={(e) => {
                const selectedType = e.target.value;
                setShowPropertyKeyInput(selectedType === SourceType.KeyValue);
                setShowRuleValueInput(selectedType !== '');
                onChange(e);
              }}
              displayEmpty
              fullWidth
              error={!!errors.ruleType}
            >
              <MenuItem disabled value="">
                Select Rule Type
              </MenuItem>
              {Object.values(SourceType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors.ruleType?.message && (
          <Typography color="red">{errors.ruleType.message}</Typography>
        )}
        {showPropertyKeyInput && (
          <Controller
            control={control}
            name="ruleKeyValue"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.ruleKeyValue}
                fullWidth
                label="Property Key"
                margin="normal"
              />
            )}
          />
        )}
        {errors.ruleKeyValue?.message && showPropertyKeyInput && (
          <Typography color="red">{errors.ruleKeyValue.message}</Typography>
        )}

        {showRuleValueInput && (
          <Controller
            control={control}
            name="ruleValue"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.ruleValue}
                fullWidth
                label="Rule Value"
                margin="normal"
              />
            )}
          />
        )}
        {errors.ruleValue?.message && showRuleValueInput && (
          <Typography color="red">{errors.ruleValue.message}</Typography>
        )}
      </Box>
      <FullWidthColorPicker
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
      />
      <Box ml={'auto'}>
        <Button
          color="primary"
          variant="outlined"
          type="submit"
          onClick={handleSubmit(handleSubmitRule)}
        >
          {editingRule ? 'Update' : 'Save'} Rule
        </Button>
        <Button sx={{ marginLeft: 1 }} color="secondary" onClick={closeForm}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AddRuleForm;
