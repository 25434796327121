import { HeatmapLayer } from 'mapbox-gl';

const MAX_ZOOM_LEVEL = 20;

export const heatmapLayer: HeatmapLayer = {
  id: 'competitor-density-heatmap',
  minzoom: 1,
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': 5,
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': 2,
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(0,0,0,0)',
      0.2,
      '#008000',
      0.4,
      '#FFFF00',
      0.6,
      '#FFA500',
      0.8,
      '#FF8C00',
      1,
      '#FF0000',
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      5,
      MAX_ZOOM_LEVEL,
      45,
    ],
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7,
      1,
      MAX_ZOOM_LEVEL,
      0,
    ],
  },
};
