import { useEffect, useState } from 'react';

import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useSettingsStore from '../../../global/hooks/useSettingsStore';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useZonesStore from '../../dynamic-map/hooks/useZonesStore';

import {
  Button,
  ButtonProps,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';

const ZoneButton: React.FC<ButtonProps> = (props) => (
  <Button
    sx={{
      backgroundColor: 'transparent',
      color: 'text.primary',
      width: '100%',
      height: '100%',
      padding: '10px',
      border: '1px solid #ccc', // Adjust border width and color as needed
      '&:hover': {
        backgroundColor: '#6a49ff', // Make sure to define the color, or replace it with a valid CSS color value
        color: 'white',
      },
      '&.clicked': {
        backgroundColor: '#fc8357',
        color: 'white',
      },
    }}
    {...props}
  />
);

export const ZonesMenu = () => {
  const zonesVisible = useDynamicMapStore((state) => state.zonesVisible);
  const toggleZonesVisible = useDynamicMapStore(
    (state) => state.toggleZonesVisible
  );
  const zones = useZonesStore((state) => state.zones);
  const userSettings = useSettingsStore((state) => state.userSettings);

  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const map = useMapContext();

  useEffect(() => {
    if (map != null) {
      const handleDragEnd = () => {
        setClickedIndex(null);
      };

      map.on('dragend', handleDragEnd);

      return () => {
        map.off('dragend', handleDragEnd);
      };
    }
    return;
  }, [map]);

  return (
    <div style={{ overflow: 'auto', maxHeight: '45vh' }}>
      {userSettings && zones && map ? (
        <Grid container spacing={2} direction="column">
          <Grid item container justifyContent="center" alignItems="center">
            <FormControlLabel
              control={
                <Switch checked={zonesVisible} onChange={toggleZonesVisible} />
              }
              style={{ margin: 0 }}
              label="Show Zones"
            />
          </Grid>
          <Grid container item spacing={1}>
            {zonesVisible &&
              zones &&
              Object.values(zones)
                .flatMap((cityData) =>
                  cityData.features.map((feature) => feature)
                )
                .filter((feature) => feature.properties?.national_rank != null)
                .sort(
                  (a, b) =>
                    (a.properties?.national_rank || 0) -
                    (b.properties?.national_rank || 0)
                )
                .map((feature, index) => (
                  <Grid item xs={4} key={index}>
                    <ZoneButton
                      className={clickedIndex === index ? 'clicked' : ''}
                      onClick={() => {
                        const coordinates = feature.geometry.coordinates as [
                          number,
                          number,
                        ];
                        const currentZoom = map.getZoom();
                        const targetZoom = Math.max(11, currentZoom);

                        if (coordinates) {
                          map.flyTo({
                            center: coordinates,
                            zoom: targetZoom,
                          });
                        }
                        setClickedIndex(index); // To mark this zone as clicked
                      }}
                    >
                      {`Zone ${feature.properties?.national_rank}`}
                    </ZoneButton>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};
