import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import dogtopiaData from '../../../data/other/pricing_demo/dogtopia.json';
import massageEnvyData from '../../../data/other/pricing_demo/massageenvy.json';
import orangetheoryfitnessData from '../../../data/other/pricing_demo/orangetheoryfitness.json';

const env = parseEnv({ API_V2: process.env.API_V2 });

type OTFData = {
  geolocation: number[];
  [key: string]: unknown;
};

// Function to convert individual file data to GeoJSON FeatureCollection
const convertToGeoJSON = (
  data: Record<string, OTFData>
): FeatureCollection<Geometry, GeoJsonProperties> => {
  const features = Object.entries(data).map(([key, value]) => {
    const { geolocation, ...properties } = value; // Exclude geolocation from the properties spread
    return {
      type: 'Feature' as const,
      geometry: {
        type: 'Point' as const,
        coordinates: [geolocation[1], geolocation[0]], // Swap if necessary to match your coordinate system (longitude, latitude)
      },
      properties: { ...properties, id: key }, // Spread remaining properties excluding geolocation
    };
  });

  return {
    type: 'FeatureCollection' as const,
    features,
  };
};

// Fetch individual pricing data file and convert it to GeoJSON
export async function fetchPricingDataFile(
  filename: string
): Promise<FeatureCollection<Geometry, GeoJsonProperties>> {
  const response = await axios.get<Record<string, OTFData>>(
    `${env.API_V2}/client/shared/pricing_data/${filename}`
  );
  if (response.status !== 200) {
    throw new Error(`Error fetching pricing data file: ${response.status}`);
  }
  return convertToGeoJSON(response.data);
}

// Function to fetch the list of pricing data files and then fetch each file
// export async function fetchAllPricingData(): Promise<
//   FeatureCollection<Geometry, GeoJsonProperties>[]
// > {
// const response = await fetch(`${env.API_V2}/client/shared/pricing_data`);
// if (!response.ok) {
//   throw new Error('Failed to fetch pricing data file list');
// }
// const files: string[] = await response.json();

// const PricingData = await Promise.all(
//   files.map((file) => fetchPricingDataFile(file))
// );

// return PricingData;
// }

export function fetchAllPricingData(): FeatureCollection<
  Geometry,
  GeoJsonProperties
>[] {
  const localFiles = [
    { name: 'dogtopia', data: dogtopiaData },
    { name: 'massageenvy', data: massageEnvyData },
    { name: 'orangetheoryfitness', data: orangetheoryfitnessData },
  ];

  return localFiles
    .filter((file) => Object.keys(file.data).length > 0)
    .map((file) => convertToGeoJSON(file.data as Record<string, OTFData>));
}
