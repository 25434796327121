import { create } from 'zustand';
import { MapRef } from 'react-map-gl';
import React from 'react';
import { InitialViewState } from '../types/initialViewState';

export enum DataSections {
  TradeArea = 'trade-area',
  CustomerSummary = 'customer-summary',
  Competitors = 'competitors',
  Generators = 'generators',
  MobileOverlap = 'mobile-overlap',
}

export interface PulseReportStore {
  mapRefs: Map<DataSections, React.RefObject<MapRef>>;
  setMapRef: (section: DataSections, ref: React.RefObject<MapRef>) => void;

  mapInitialViewStates: Map<DataSections, InitialViewState>;
  setMapInitialViewState: (
    section: DataSections,
    initialViewState: InitialViewState
  ) => void;

  mapPins: Map<DataSections, JSX.Element[]>;
  setMapPins: (section: DataSections, pins: JSX.Element[]) => void;
}

const usePulseReportStore = create<PulseReportStore>((set) => ({
  mapRefs: new Map(),
  setMapRef: (section, ref) => {
    set((state) => {
      const updatedMapRefs = new Map(state.mapRefs);
      updatedMapRefs.set(section, ref);

      return {
        mapRefs: updatedMapRefs,
      };
    });
  },

  mapInitialViewStates: new Map(),
  setMapInitialViewState: (section, initialViewState) => {
    set((state) => {
      const current = state.mapInitialViewStates;
      current.set(section, initialViewState);

      return {
        mapInitialViewStates: current,
      };
    });
  },

  mapPins: new Map(),
  setMapPins: (section, pins) => {
    set((state) => {
      const updatedMapPins = new Map(state.mapPins);
      updatedMapPins.set(section, pins);

      return {
        mapPins: updatedMapPins,
      };
    });
  },
}));

export default usePulseReportStore;
