import { Layer } from 'react-map-gl';
import useDynamicMapStore from '../../../hooks/useDynamicMapStore';
import useLayerIds from '../../../hooks/useLayerIds';
import useMapImage from '../../../hooks/useMapImage';
import { ExtendedPlacesResult } from '../../../services/poiService';
import { defaultPOILayer } from '../POIHelpers';

export default function POILocalFilterLayer({
  placesResult,
}: {
  placesResult: ExtendedPlacesResult;
}) {
  const layerId = `poi-${placesResult.id}`;
  const imageId = `${placesResult.id}-pin`;

  const badgeId = placesResult.badge?.id;
  const [badgeLayer] = useLayerIds(
    (layer) => layer.id === `poi-${badgeId}-badge`
  );

  const poisEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const clickedPoiFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );
  const isPinReady = useMapImage({
    id: imageId,
    src: placesResult.pinImage,
  });
  const poiLabelsEnabled = useDynamicMapStore(
    (state) => state.poiLabelsEnabled
  );

  return (
    'localFilter' in placesResult &&
    Array.isArray(placesResult.localFilter) &&
    placesResult.localFilter.length > 0 && (
      <Layer
        {...defaultPOILayer}
        id={layerId}
        layout={{
          ...defaultPOILayer.layout,
          ...(isPinReady ? { 'icon-image': imageId } : {}),
          visibility: poisEnabled ? 'visible' : 'none',
          'text-field': poiLabelsEnabled ? ['get', 'name'] : '',
          'text-offset': [0, 1.5],
          'text-anchor': 'top',
        }}
        paint={{
          'text-color': 'black',
          'text-halo-color': 'white',
          'text-halo-width': 1,
        }}
        filter={
          clickedPoiFeature
            ? [
                'all',
                placesResult.localFilter,
                ['!=', ['get', 'id'], clickedPoiFeature?.properties?.id],
              ]
            : placesResult.localFilter
        }
        metadata={{
          placeId: placesResult.id,
          placeName: placesResult.name,
        }}
        beforeId={badgeLayer}
      />
    )
  );
}
