import { Box, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Layer, MapMouseEvent, Source } from 'react-map-gl';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useLayerIds from '~/src/features/dynamic-map/hooks/useLayerIds';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';

const TradeAreaHeatmapSource: React.FC = () => {
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );
  const tradeAreaData = useDemographicStore((state) => state.tradeAreaData);
  const tradeAreaDataIsLoading = useDemographicStore(
    (state) => state.tradeAreaDataIsLoading
  );
  const showTradeAreaHeatmapData = useDemographicStore(
    (state) => state.showTradeAreaHeatmapData
  );
  const setShowTradeAreaHeatmap = useDemographicStore(
    (state) => state.setShowTradeAreaHeatmap
  );
  const roadLayerId = useLayerIds((layer) =>
    layer.id.startsWith('road-label')
  )[0];
  const mobileDataCalculated = useDemographicStore(
    (state) => state.mobileDataCalculated
  );

  const expandedMobileDataAccordion = useDynamicMapStore(
    (state) => state.expandedMobileDataAccordion
  );

  const map = useMapContext();

  const [hoverInfo, setHoverInfo] = useState<any>(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Memoized values to optimize re-renders
  const values = useMemo(() => {
    if (tradeAreaData === null) {
      setShowTradeAreaHeatmap(false);
      return [];
    }
    return tradeAreaData instanceof Map
      ? Array.from(tradeAreaData.values())
      : [];
  }, [tradeAreaData, setShowTradeAreaHeatmap]);

  const minValue = useMemo(() => Math.min(...values, 0), [values]);
  const maxValue = useMemo(() => Math.max(...values, 0.0025), [values]);

  const colorStops = useMemo(
    () => [
      [minValue, '#FFF'],
      [minValue + (maxValue - minValue) * 0.25, '#FED976'],
      [minValue + (maxValue - minValue) * 0.5, '#FEB24C'],
      [minValue + (maxValue - minValue) * 0.75, '#FD8D3C'],
      [maxValue, '#FC4E2A'],
    ],
    [minValue, maxValue]
  );

  const cleanedTradeAreaData = useMemo(
    () =>
      tradeAreaData instanceof Map
        ? Array.from(tradeAreaData).flatMap(([key, value]) => [
            parseInt(key),
            value,
          ])
        : [],
    [tradeAreaData]
  );

  // Conditions to determine rendering
  const shouldRender = useMemo(
    () =>
      !tradeAreaDataIsLoading &&
      evaluatedDemographicEntity &&
      tradeAreaData &&
      tradeAreaData instanceof Map &&
      tradeAreaData.size > 0 &&
      expandedMobileDataAccordion === 'mobileVisits',
    [
      tradeAreaDataIsLoading,
      evaluatedDemographicEntity,
      tradeAreaData,
      expandedMobileDataAccordion,
    ]
  );

  useEffect(() => {
    if (
      !map ||
      expandedMobileDataAccordion !== 'mobileVisits' ||
      !showTradeAreaHeatmapData ||
      tradeAreaData?.size === 0
    )
      return;

    const onMouseMove = (e: MapMouseEvent) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['block-group-heatmap-layer', 'block-group-outline-layer'],
      });

      const relevantFeature = features.find(
        (feature) =>
          feature.layer.id === 'block-group-heatmap-layer' ||
          feature.layer.id === 'block-group-outline-layer'
      );
      if (relevantFeature) {
        setCursorPosition({ x: e.point.x, y: e.point.y });

        const geoid = relevantFeature.id?.toString();
        let visitsGeoId = 0;

        if (geoid && tradeAreaData?.has(geoid)) {
          visitsGeoId = tradeAreaData.get(geoid) ?? 0;
        }

        const total_visits = Math.round(
          visitsGeoId * mobileDataCalculated.visits_sum
        );

        setHoverInfo({
          data: relevantFeature,
          visitsSum: total_visits,
          geoid: geoid,
        });
      } else {
        setHoverInfo(null);
      }
    };

    const onMouseOut = () => {
      setHoverInfo(null);
    };

    map.on('mousemove', onMouseMove);
    map.on('mouseout', onMouseOut);

    return () => {
      map.off('mousemove', onMouseMove);
      map.off('mouseout', onMouseOut);
    };
  }, [
    map,
    showTradeAreaHeatmapData,
    tradeAreaData,
    mobileDataCalculated.visits_sum,
    expandedMobileDataAccordion,
  ]);

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <Source
        id="block-group-source"
        type="vector"
        url="mapbox://luketruitt1.block_group_insights"
      >
        <Layer
          id="block-group-heatmap-layer"
          type="fill"
          source="block-group-source"
          source-layer="block_group_insights"
          paint={{
            'fill-color': [
              'interpolate',
              ['linear'],
              ['match', ['id'], ...cleanedTradeAreaData, 0],
              ...colorStops.flat(),
            ],
            'fill-opacity': [
              'case',
              ['>', ['match', ['id'], ...cleanedTradeAreaData, 0], 0],
              0.32,
              0,
            ],
            'fill-outline-color': [
              'interpolate',
              ['linear'],
              ['match', ['id'], ...cleanedTradeAreaData, 0],
              ...colorStops.flat(),
            ],
          }}
          beforeId={roadLayerId ?? undefined}
        />
        <Layer
          id="block-group-outline-layer"
          type="line"
          source="block-group-source"
          source-layer="block_group_insights"
          paint={{
            'line-color': 'transparent',
          }}
          beforeId={roadLayerId ?? undefined}
        />
      </Source>

      {showTradeAreaHeatmapData && hoverInfo && (
        <Box
          position="absolute"
          top={cursorPosition.y + 10}
          left={cursorPosition.x + 10}
          zIndex={1000}
        >
          <Card raised>
            <CardContent>
              <Typography variant="body1" fontWeight="bold">
                {hoverInfo.geoid}
              </Typography>
              <Typography variant="body1">
                {hoverInfo.visitsSum} Visits
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default TradeAreaHeatmapSource;
