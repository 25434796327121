import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import SummaryDisplay from './components/SummaryDisplay';
import useDemographicStore from './hooks/useDemographicStore';

export default function DemographicPulseData() {
  const summaryData = useDemographicStore(
    (state) => state.demographicSummaryData
  );

  const isDemographicDataLoading = useDemographicStore(
    (state) => state.isDemographicDataLoading
  );

  return (
    <Box height={1} display="flex" flexDirection="column">
      <Box flex="1">
        {isDemographicDataLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Getting Pulse...
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            {summaryData?.us && (
              <Accordion
                sx={{
                  marginBottom: 1,
                  '& .MuiAccordionSummary-root': {
                    backgroundColor: '#f5f5f5',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                  },
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="us-pulse-content"
                  id="us-pulse-header"
                >
                  <Typography sx={{ fontWeight: 'medium' }}>
                    US Pulse Data
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SummaryDisplay dataPoints={summaryData.us?.data ?? []} />
                </AccordionDetails>
              </Accordion>
            )}
            {summaryData?.ca && (
              <Accordion
                sx={{
                  '& .MuiAccordionSummary-root': {
                    backgroundColor: '#f5f5f5',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                  },
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="ca-pulse-content"
                  id="ca-pulse-header"
                >
                  <Typography sx={{ fontWeight: 'medium' }}>
                    CA Pulse Data
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SummaryDisplay dataPoints={summaryData.ca?.data ?? []} />
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
