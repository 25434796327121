import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';
import {
  plotrMultiplayerData,
  Rule,
  SourceType,
} from '@plotr/plotr-multiplayer-data/src';

type RulesListProps = {
  rules: Rule[];
  startEditing: (id: string) => void;
};

const RulesList = ({ rules, startEditing }: RulesListProps) => {
  const rulesetsMethods = plotrMultiplayerData.methods?.rulesets;

  return (
    <Box pt={1}>
      {rules.map((rule) => (
        <Box
          key={rule.id}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <Box display="flex" gap={1}>
            <Box
              width={24}
              height={24}
              bgcolor={rule.effect}
              borderRadius={'50%'}
            />
            <Typography height={'fit-content'} my={'auto'}>
              {rule.sourceType === SourceType.KeyValue
                ? rule.propertyKey
                : rule.sourceType}
              : {rule.evaluation.value}
            </Typography>
          </Box>
          <Box display="flex">
            <IconButton
              onClick={() => {
                startEditing(rule.id);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => rulesetsMethods?.deleteRule(rule.id)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default RulesList;
