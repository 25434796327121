import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { formatPercentage } from '~/src/common/helpers/formatPercentage';

interface OverlapPerPinProps {
  data: {
    label: string;
    value: { percentage: number; visits: number };
  }[];
  style?: 'normal' | 'bold';
  hideVisits?: boolean;
}

export const OverlapPerPin = ({
  data,
  style = 'normal',
  hideVisits = false,
}: OverlapPerPinProps) => {
  return (
    <Table size="small">
      <TableBody>
        {data.map(({ label, value }, index) => {
          const hideBorderObj =
            index === data.length - 1 ? { borderBottom: 'none' } : {};
          const boldObj = style === 'bold' ? { fontWeight: 'bold' } : {};

          return (
            <TableRow key={label}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  ...boldObj,
                  ...hideBorderObj,
                }}
              >
                {label}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  ...hideBorderObj,
                }}
              >
                {!hideVisits && `(${value.visits})`}{' '}
                {formatPercentage(value.percentage)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
