import { FeatureCollection } from 'geojson';
import {
  ControllableLayerGroup,
  LayerDataConfig,
  LayerStyleConfig,
} from '~/src/features/dynamic-map/hooks/useLayersStore';
import LayerImageCategories from './components/EditLayerModal/LayerImageCategories';

export enum LayerType {
  Insights = 'Insights Layer',
  Enterprise = 'Enterprise Layer',
  IndustryData = 'Industry Data Layer',
  LayerSet = 'Layer Set',
}

export interface CustomLabel {
  show?: boolean;
  value?: string;
}

export type LayerImageCategoryKey = keyof typeof LayerImageCategories;
export type LayerFeatureType = 'polygon' | 'line' | 'point';
export type GroupNameType =
  | 'boundary_layers'
  | 'demographic_layers'
  | 'psychographic_layers'
  | 'custom_layers'
  | 'Zones'
  | 'search_layers'
  | 'traffic_layers';

export interface BaseLayerConfig {
  displayName: string;
  groupName: GroupNameType;
  permission?: string;
  idLabelFlag?: boolean;
  customMinLabel?: CustomLabel;
  customMaxLabel?: CustomLabel;
  category: LayerImageCategoryKey;
  featureType: LayerFeatureType;
  isBoundary?: boolean;
  mergeGroup?: string;
  class?: string;
  filter?: Expression;
  controllableComponent?: React.FunctionComponent;
}

export interface TilesetSource {
  source: string;
  source_layer: string;
  label: string;
}

export type BlockGroupSource = {
  label: 'Block Groups';
  source: 'luketruitt1.block_group_insights';
  source_layer: 'block_group_insights';
} & TilesetSource;

export type ZipcodeSource = {
  label: 'Zip Codes';
  source: 'luketruitt1.insights_zipcode';
  source_layer: 'insights_zipcode';
} & TilesetSource;

export type OtherTilesetSource = {
  label: string;
  source: string;
  source_layer: string;
} & TilesetSource;

export type GeoJSONSource = {
  label: 'GeoJSON';
  source: string | FeatureCollection;
};

export type InsightTilesetSources =
  | BlockGroupSource
  | ZipcodeSource
  | OtherTilesetSource
  | GeoJSONSource;

export type Expression = Array<ExpressionValue>;
type ExpressionValue = string | number | boolean | Expression;

export interface InsightLayerConfig extends BaseLayerConfig {
  type: LayerType.Insights;
  sourceTileset: InsightTilesetSources;
  styleConfig: LayerStyleConfig;
  dataConfig?: LayerDataConfig;
}

export interface EnterpriseLayerConfig extends BaseLayerConfig {
  type: LayerType.Enterprise;
  component: ControllableLayerGroup;
  styleConfig: LayerStyleConfig;
  dataConfig?: LayerDataConfig;
}

export type LayerConfig = InsightLayerConfig | EnterpriseLayerConfig;
