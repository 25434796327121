import {
  DeleteSweep,
  ExpandMore as ExpandMoreIcon,
  TextFields,
  TextFieldsOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { memo, useMemo } from 'react';
import tinycolor from 'tinycolor2';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import usePOIStore from '../../dynamic-map/hooks/usePOIStore';

interface POIMenuProps {
  isCustomPOIBrands?: boolean;
}

export const POIMenu = (props: POIMenuProps) => {
  const { isCustomPOIBrands = false } = props;

  const poisEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const togglePoisEnabled = useDynamicMapStore(
    (state) => state.togglePoisEnabled
  );
  const poiLabelsEnabled = useDynamicMapStore(
    (state) => state.poiLabelsEnabled
  );
  const togglePoiLabelsEnabled = useDynamicMapStore(
    (state) => state.togglePoiLabelsEnabled
  );

  const poiGroups = usePOIStore((state) => state.poiGroups);
  const selectedPOIGroups = useDynamicMapStore(
    (state) => state.selectedPOIGroups
  );
  const togglePOIGroup = useDynamicMapStore((state) => state.togglePOIGroup);

  const selectedBrands = useDynamicMapStore((state) => state.selectedBrands);
  const toggleBrand = useDynamicMapStore((state) => state.toggleBrand);

  const resetPOIs = useDynamicMapStore((state) => state.resetPOIs);

  const selectedBrandIds = selectedBrands.map((brand) => brand.id);

  const expandedMobileDataAccordion = useDynamicMapStore(
    (state) => state.expandedMobileDataAccordion
  );
  const setExpandedMobileDataAccordion = useDynamicMapStore(
    (state) => state.setExpandedMobileDataAccordion
  );

  interface MemoizedChipProps {
    label: string;
    clickable?: boolean;
    onClick?: () => void;
    industry: string;
    color?: string;
    style?: React.CSSProperties;
  }

  const MemoizedChip = memo((props: MemoizedChipProps) => {
    const { label, onClick, color } = props;
    const textColor = tinycolor(color).getLuminance() < 0.6 ? '#fff' : '#000';
    const chipStyle = {
      margin: '0.2rem',
      backgroundColor: color,
      color: color === 'default' ? undefined : textColor,
    };

    return <Chip label={label} onClick={onClick} style={chipStyle} />;
  });
  MemoizedChip.displayName = 'POIBrandName';

  const poiGroupData = useMemo(() => {
    const customPOIBrands = ['Competitor Locations', 'Proxy Brand Locations'];

    const filteredPOIGroups = poiGroups
      .map(({ group, color, brandResults, queryResults }) => ({
        name: group,
        color: color,
        brandGroup: [...brandResults, ...queryResults],
      }))
      .filter(
        ({ name }) => isCustomPOIBrands === customPOIBrands.includes(name)
      );

    return filteredPOIGroups;
  }, [isCustomPOIBrands]);

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={-1}
        marginBottom={2}
      >
        <Typography variant="h6" marginRight={1}>
          POINTS OF INTEREST
        </Typography>
        <Box display="flex" alignItems="center">
          <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
          <Box display="flex" alignItems="center" gap={1}>
            <Tooltip
              title={
                poisEnabled
                  ? 'Hide Points of Interest'
                  : 'Show Points of Interest'
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={togglePoisEnabled}
                color={poisEnabled ? 'primary' : 'default'}
              >
                {poisEnabled ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={poiLabelsEnabled ? 'Hide POI Labels' : 'Show POI Labels'}
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={togglePoiLabelsEnabled}
                color={poisEnabled && poiLabelsEnabled ? 'primary' : 'default'}
                disabled={!poisEnabled}
              >
                {poiLabelsEnabled ? <TextFields /> : <TextFieldsOutlined />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear all POIs">
              <IconButton onClick={resetPOIs} size="small">
                <DeleteSweep />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider />
      <div className="custom-scrollbar">
        {poiGroupData.map((poiGroup) => {
          const isChecked = selectedPOIGroups.includes(poiGroup.name);

          return (
            <Accordion
              key={poiGroup.name}
              expanded={expandedMobileDataAccordion === poiGroup.name}
              onChange={(e, isExpanded) => {
                setExpandedMobileDataAccordion(
                  isExpanded ? poiGroup.name : false
                );
              }}
              sx={{ margin: '0 !important' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor:
                    expandedMobileDataAccordion === poiGroup.name
                      ? (theme) => theme.palette.grey[200]
                      : 'inherit',
                }}
              >
                <FormControlLabel
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePOIGroup(poiGroup.name);
                  }}
                  control={<Checkbox checked={isChecked} />}
                  label={poiGroup.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {poiGroup.brandGroup
                    .sort((a, b) =>
                      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    )
                    .map((brand) => {
                      const isBrandSelected = selectedBrandIds.includes(
                        brand.id
                      );
                      return (
                        <MemoizedChip
                          key={brand.id}
                          label={brand.name}
                          clickable
                          industry={poiGroup.name}
                          color={isBrandSelected ? poiGroup.color : '#ddd'}
                          onClick={() => {
                            toggleBrand(brand.id);
                          }}
                          style={{ margin: '0.2rem' }}
                        />
                      );
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Box>
  );
};
