import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import HubspotContactForm from '~/src/common/components/HubspotContactForm';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';

export enum HubspotModalType {
  Mobile = 'mobile',
  Sonar = 'sonar',
  SonarBrand = 'sonar-brand',
}

interface HubspotModalProps {
  open: boolean;
  onClose: () => void;
  formType: HubspotModalType;
}

const TITLES: Record<HubspotModalType, (brand: string | undefined) => string> =
  {
    [HubspotModalType.Mobile]: () => 'Mobile Data Access',
    [HubspotModalType.Sonar]: () => 'Sonar Data Access',
    [HubspotModalType.SonarBrand]: (brand) =>
      brand ? `Sonar Data Access for ${brand}` : `Sonar Data Access`,
  };

const DESCRIPTIONS: Record<
  HubspotModalType,
  (brand: string | undefined) => string
> = {
  [HubspotModalType.Mobile]: () =>
    'Get access to detailed mobile data insights for this location. Fill out the form below to request access.',
  [HubspotModalType.Sonar]: () =>
    'Unlock Sonar data to assess the likelihood of store closures. Complete the form to request access to this feature.',
  [HubspotModalType.SonarBrand]: (brand) =>
    `Unlock Sonar data${brand ? `for ${brand}` : ''} to access the likelihood of store closures. Complete the form to request access to this feature.`,
};

const HubspotModal: React.FC<HubspotModalProps> = ({
  open,
  onClose,
  formType,
}) => {
  const clickedPOIFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );

  const { name: brand } = clickedPOIFeature?.properties ?? {};

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" component="h2" gutterBottom>
          {TITLES[formType](brand)}
        </Typography>
        <Typography variant="body1" paragraph>
          {DESCRIPTIONS[formType](brand)}
        </Typography>
        <HubspotContactForm type={formType} onFormSubmit={onClose} />
      </Box>
    </Modal>
  );
};

export default HubspotModal;
