import {
  ArrowBack as ArrowBackIcon,
  ArrowRight as ArrowRightIcon,
  TextFields,
  TextFieldsOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { useMemo } from 'react';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import GroupCustomPinsMenu from './GroupCustomPinsMenu';
import MainCustomPinsMenu from './MainCustomPinsMenu';

import {
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { TargetType } from '@plotr/plotr-multiplayer-data/src';
import CollapseCard from '~/src/common/components/CollapseCard';
import RulesCollapseSection from '../../rules-collapse';

const CustomPinsMenu = () => {
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const setEvaluatedPinId = useDynamicMapStore(
    (state) => state.setEvaluatedPinId
  );

  const selectedPinGroup = useDynamicMapStore(
    (state) => state.selectedPinGroup
  );
  const setSelectedPinGroup = useDynamicMapStore(
    (state) => state.setSelectedPinGroup
  );

  const customPinsEnabled = useDynamicMapStore(
    (state) => state.customPinsEnabled
  );
  const toggleCustomPinsEnabled = useDynamicMapStore(
    (state) => state.toggleCustomPinsEnabled
  );

  const pinLabelsEnabled = useDynamicMapStore(
    (state) => state.pinLabelsEnabled
  );
  const togglePinLabelsEnabled = useDynamicMapStore(
    (state) => state.togglePinLabelsEnabled
  );

  const customPins = useCustomPins();
  const evaluatedPin = useMemo(() => {
    if (evaluatedPinId == null) return null;
    return customPins.find(({ id }) => id === evaluatedPinId);
  }, [customPins, evaluatedPinId]);

  const breadcrumbs: { label: string; onClick?: () => void }[] = useMemo(() => {
    const defaultBreadcrumbs = [
      {
        label: 'Pin Groups',
        ...(selectedPinGroup != null || evaluatedPin != null
          ? {
              onClick: () => {
                setSelectedPinGroup(null);
                setEvaluatedPinId(null);
              },
            }
          : {}),
      },
    ];

    if (evaluatedPin == null && selectedPinGroup != null) {
      return [...defaultBreadcrumbs, { label: selectedPinGroup }];
    }

    if (evaluatedPin != null) {
      return [
        ...defaultBreadcrumbs,
        {
          label: evaluatedPin.group,
          onClick: () => {
            setSelectedPinGroup(evaluatedPin.group);
            setEvaluatedPinId(null);
          },
        },
        { label: evaluatedPin.label },
      ];
    }

    return defaultBreadcrumbs;
  }, [evaluatedPin, selectedPinGroup, setEvaluatedPinId, setSelectedPinGroup]);

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={-1}
        marginBottom={2}
      >
        <Typography variant="h6" marginRight={1}>
          YOUR PINS
        </Typography>
        <Box display="flex" alignItems="center">
          <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
          <Box display="flex" alignItems="center" gap={1}>
            <Tooltip
              title={
                customPinsEnabled ? 'Hide Custom Pins' : 'Show Custom Pins'
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={toggleCustomPinsEnabled}
                color={customPinsEnabled ? 'default' : 'warning'}
              >
                {customPinsEnabled ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={pinLabelsEnabled ? 'Hide Pin Labels' : 'Show Pin Labels'}
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={togglePinLabelsEnabled}
                color={
                  customPinsEnabled && pinLabelsEnabled ? 'primary' : 'default'
                }
                disabled={!customPinsEnabled}
              >
                {pinLabelsEnabled ? <TextFields /> : <TextFieldsOutlined />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
        {(selectedPinGroup != null || evaluatedPin != null) && (
          <IconButton
            onClick={() => {
              if (evaluatedPin != null) {
                setEvaluatedPinId(null);
              } else if (selectedPinGroup != null) {
                setSelectedPinGroup(null);
              }
            }}
            sx={{ padding: 0, marginRight: 1 }}
          >
            <ArrowBackIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
        <Breadcrumbs
          separator={<ArrowRightIcon sx={{ margin: '0 -5px' }} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((crumb, index) => {
            const clickable = crumb.onClick != null;
            const textColor =
              clickable || index === 0 ? 'text.primary' : 'text.disabled';

            return (
              <Typography
                key={crumb.label}
                variant="body1"
                color={textColor}
                onClick={clickable ? crumb.onClick : undefined}
                sx={{
                  cursor: clickable ? 'pointer' : 'default',
                  textDecoration: clickable ? 'underline' : 'none',
                }}
              >
                {crumb.label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Box>
      <Box
        paddingY={2}
        flex="1 1 auto"
        overflow="auto"
        position="relative"
        gap={1}
      >
        {selectedPinGroup != null ? (
          <GroupCustomPinsMenu />
        ) : (
          <MainCustomPinsMenu />
        )}
      </Box>
      <Box>
        <CollapseCard title="Global Pins Settings">
          <Box py={1} px={2}>
            <RulesCollapseSection targetType={TargetType.Pins} />
          </Box>
        </CollapseCard>
      </Box>
    </Box>
  );
};

export default CustomPinsMenu;
