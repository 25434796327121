import { useEffect, useState } from 'react';
import { ColorState, SketchPicker, SketchPickerProps } from 'react-color';

const MAX_PREV_COLORS = 36;

const CustomColorPicker = (props: SketchPickerProps) => {
  const [prevColors, setPrevColors] = useState<string[]>([]);

  useEffect(() => {
    const colors = props.presetColors;
    const savedColors =
      JSON.parse(
        localStorage.getItem('prevColors-custom-color-picker') ?? '[]'
      ) ?? [];

    setPrevColors(
      [
        ...new Set([
          ...((colors as string[]) ?? []),
          ...((savedColors as string[]) ?? []),
        ]),
      ].slice(0, MAX_PREV_COLORS)
    );
  }, [props.presetColors]);

  const handleChangeComplete = (color: ColorState) => {
    props.onChangeComplete?.(color);

    const index = prevColors.indexOf(color.hex);
    if (index !== -1) {
      prevColors.splice(index, 1);
    }

    setPrevColors((prevColors) => {
      const newPrevColors = [color.hex, ...prevColors].slice(
        0,
        MAX_PREV_COLORS
      );
      localStorage.setItem(
        'prevColors-custom-color-picker',
        JSON.stringify(newPrevColors)
      );
      return newPrevColors;
    });
  };

  return (
    <SketchPicker
      {...props}
      presetColors={prevColors}
      onChangeComplete={handleChangeComplete}
    />
  );
};

export default CustomColorPicker;
