import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    IconButton,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography,
} from '@mui/material';
import { GeoJsonProperties } from 'geojson';
import React, { useEffect, useState } from 'react';
import { Layer, MapLayerMouseEvent, Popup, Source } from 'react-map-gl';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import usePricingDataStore from '../../dynamic-map/hooks/usePricingDataStore';

interface EventListener {
  layerId: string;
  handler: (event: MapLayerMouseEvent) => void;
}

type ClickLocation = {
  longitude: number;
  latitude: number;
  properties: GeoJsonProperties;
} | null;

type SelectedTab = string;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const parseIfJson = (value: any) => {
  if (
    typeof value === 'string' &&
    (value.startsWith('{') || value.startsWith('['))
  ) {
    try {
      const parsed = JSON.parse(value);
      if (typeof parsed === 'object' && parsed !== null) {
        return parsed;
      }
    } catch (e) {
      // If parsing fails, return the original value
    }
  }
  return value;
};

const renderNestedTable = (value: any) => (
  <TableContainer component={Paper}>
    <Table size="small">
      <TableBody>
        {Object.entries(value).map(([nestedKey, nestedValue]) => (
          <TableRow key={nestedKey}>
            <TableCell component="th" scope="row">
              {capitalizeFirstLetter(nestedKey)}
            </TableCell>
            <TableCell>{renderValue(nestedValue)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const renderValue = (value: any) => {
  const parsedValue = parseIfJson(value);
  if (
    typeof parsedValue === 'object' &&
    parsedValue !== null &&
    !Array.isArray(parsedValue)
  ) {
    return renderNestedTable(parsedValue);
  } else {
    return <Typography variant="body2">{String(value)}</Typography>;
  }
};

const renderOverview = (properties: GeoJsonProperties): React.ReactNode => {
  if (!properties) return null;

  const simpleEntries = Object.entries(properties).filter(
    ([, value]) =>
      typeof parseIfJson(value) !== 'object' ||
      parseIfJson(value) === null ||
      Array.isArray(parseIfJson(value))
  );

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {simpleEntries.map(([key, value]) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {capitalizeFirstLetter(key)}
              </TableCell>
              <TableCell>{renderValue(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TabPanel = (props: {
  children?: React.ReactNode;
  value: any;
  index: any;
  [key: string]: any;
}) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} maxHeight="400px" overflow="auto">
          {children}
        </Box>
      )}
    </div>
  );
};

const PricingModelOutputSource: React.FC = () => {
  const { pricingData, hiddenBrands } = usePricingDataStore();
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('overview');
  const [clickLocation, setClickLocation] = useState<ClickLocation>(null);
  const map = useMapContext();

  useEffect(() => {
    if (!map) return;

    const listeners: EventListener[] = [];

    // Function to handle click events
    const handleClick = (event: MapLayerMouseEvent) => {
      const feature = event.features && event.features[0];
      if (feature && feature.geometry.type === 'Point') {
        setClickLocation({
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
          properties: feature.properties,
        });
      }
    };

    pricingData.forEach((brandData) => {
      if (!hiddenBrands.includes(brandData.brand)) {
        const layerId = `${brandData.brand}-layer`;
        map.on('click', layerId, handleClick);
        listeners.push({ layerId, handler: handleClick });
      }
    });

    // Cleanup function to remove all event listeners
    return () => {
      listeners.forEach(({ layerId, handler }) => {
        map.off('click', layerId, handler);
      });
    };
  }, [map, pricingData, hiddenBrands]);

  const closePopup = () => setClickLocation(null);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {pricingData.map(
        (brandData: { brand: any; data: any; color: any }) =>
          !hiddenBrands.includes(brandData.brand) && (
            <Source
              key={brandData.brand}
              id={`${brandData.brand}-data`}
              type="geojson"
              data={brandData.data}
            >
              <Layer
                id={`${brandData.brand}-layer`}
                type="circle"
                paint={{
                  'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    10,
                    7,
                    15,
                    20,
                  ],
                  'circle-color': brandData.color,
                }}
              />
            </Source>
          )
      )}

      {clickLocation && clickLocation?.properties && (
        <Popup
          longitude={clickLocation.longitude}
          latitude={clickLocation.latitude}
          closeButton={false}
          closeOnClick={false}
          maxWidth="calc(max(600px, 25vw))"
        >
          <Box p={1} style={{ whiteSpace: 'normal', position: 'relative' }}>
            <IconButton
              style={{ position: 'absolute', top: '0px', right: '0px' }}
              onClick={closePopup}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {clickLocation.properties?.name}
            </Typography>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="Overview" value="overview" />
              {Object.keys(clickLocation.properties ?? {})
                .filter(
                  (key) =>
                    typeof parseIfJson(clickLocation.properties?.[key]) ===
                      'object' &&
                    parseIfJson(clickLocation.properties?.[key]) !== null &&
                    !Array.isArray(parseIfJson(clickLocation.properties?.[key]))
                )
                .map((key) => (
                  <Tab
                    key={key}
                    label={capitalizeFirstLetter(key)}
                    value={key}
                  />
                ))}
            </Tabs>
            <TabPanel value={selectedTab} index="overview">
              {renderOverview(clickLocation.properties ?? {})}
            </TabPanel>
            {Object.entries(clickLocation.properties ?? {})
              .filter(
                ([, value]) =>
                  typeof parseIfJson(value) === 'object' &&
                  parseIfJson(value) !== null &&
                  !Array.isArray(parseIfJson(value))
              )
              .map(([key, value]) => (
                <TabPanel key={key} value={selectedTab} index={key}>
                  {renderNestedTable(parseIfJson(value))}
                </TabPanel>
              ))}
          </Box>
        </Popup>
      )}
    </>
  );
};

export default PricingModelOutputSource;
