import { CustomTerritory } from '@plotr/plotr-multiplayer-data';
import { Point } from 'geojson';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { DemographicEntity } from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

export const checkFeatureType = (
  clickedOnThisFeature: MapboxGeoJSONFeature | undefined
) => {
  if (!clickedOnThisFeature) {
    return { isPOI: false, isTerritory: false };
  }

  const isPOI =
    clickedOnThisFeature.sourceLayer === 'poi_places' ||
    clickedOnThisFeature.sourceLayer === 'multi_family';

  const isDefinedTerritory =
    clickedOnThisFeature.source === 'defined-territories';

  const isDrawnTerritory =
    clickedOnThisFeature.source === 'custom-drawn-territories';

  const isTerritory = isDefinedTerritory || isDrawnTerritory;

  return { isPOI, isTerritory };
};

export const getDemographicEntity = (
  clickedOnThisFeature: MapboxGeoJSONFeature | undefined,
  customTerritories: CustomTerritory[]
): {
  demographicEntity: DemographicEntity | null;
  territory?: CustomTerritory;
} => {
  if (!clickedOnThisFeature) {
    console.warn('clickedOnThisFeature is undefined');
    return { demographicEntity: null };
  }

  let demographicEntity: DemographicEntity = {
    type: 'territory',
    id: '',
  };
  let territory: CustomTerritory | undefined;

  const { isTerritory } = checkFeatureType(clickedOnThisFeature);

  const isDefinedTerritory =
    clickedOnThisFeature.source === 'defined-territories';
  const isDrawnTerritory =
    clickedOnThisFeature.source === 'custom-drawn-territories';

  if (isTerritory) {
    const featureProperties = clickedOnThisFeature.properties || {};
    if (isDefinedTerritory) {
      demographicEntity = {
        type: 'territory',
        id: featureProperties.id || '',
      };
    }
    if (isDrawnTerritory) {
      demographicEntity = {
        type: 'territory',
        id: featureProperties.territoryId || '',
      };
    }
    if (demographicEntity.type === 'territory') {
      if (isDefinedTerritory) {
        territory = customTerritories.find(
          (t) => t.id === featureProperties.id
        );
      } else if (isDrawnTerritory) {
        territory = customTerritories.find(
          (t) => t.id === featureProperties.territoryId
        );
      }
      if (!territory) {
        console.error('Territory not found:', demographicEntity.id);
        return { demographicEntity: null };
      }
    }
  } else {
    const poiFeatureLatLong = clickedOnThisFeature.geometry as Point;
    if (!poiFeatureLatLong || !poiFeatureLatLong.coordinates) {
      console.error('Invalid Point geometry:', poiFeatureLatLong);
      return { demographicEntity: null };
    }
    const latLng = {
      lat: poiFeatureLatLong.coordinates[1],
      lng: poiFeatureLatLong.coordinates[0],
    };

    demographicEntity = {
      type: 'point',
      pos: latLng,
    };
  }

  return { demographicEntity, territory };
};
