import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3408bc',
    },
    secondary: {
      main: '#ec8c2c',
    },
  },
  typography: {
    fontFamily: `"Roboto", "Arial", sans-serif`,
    h1: {
      fontWeight: 900,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    body1: {
      lineHeight: '22px',
    },
  },
});

export default theme;
