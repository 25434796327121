import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { PsychographicDetailsModal } from './PsychographicDetailsModal';
import { PsychographicData } from './usePsychographicSearch';

export const PsychographicsTopTable = ({
  data,
  isLoading,
}: {
  data: PsychographicData;
  isLoading: boolean;
}) => {
  const total = data.reduce((acc, curr) => acc + Number(curr.count), 0);
  const [psychographicTypeId, setPsychographicTypeId] = useState<number | null>(
    null
  );

  return (
    <>
      {data.length === 0 || isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={8}>
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h6" component="h2">
                  Top 10 Psychographics
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({ id, name, count, parentColor }, index) => (
              <TableRow
                key={id}
                onClick={() =>
                  name?.toLowerCase() !== 'undefined' &&
                  setPsychographicTypeId(id)
                }
                sx={{
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: `#${parentColor}70`,
                  },
                }}
              >
                <TableCell
                  sx={{ backgroundColor: `#${parentColor}` }}
                  width={8}
                />
                <TableCell>
                  <Typography variant="body2" component="p">
                    {index + 1}. {name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    component="p"
                    fontWeight={'bold'}
                    textAlign={'right'}
                  >
                    {Number(count).toLocaleString()} (
                    {Math.round((Number(count) / total) * 100)}
                    %)
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <PsychographicDetailsModal
        setPsychographicTypeId={setPsychographicTypeId}
        psychographicTypeId={psychographicTypeId}
      />
    </>
  );
};
