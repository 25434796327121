import { Box, CircularProgress } from '@mui/material';

import { Card } from '@mui/material';

export const LoadingCard = () => {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        marginBottom: '16px',
        position: 'relative',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        minHeight: '100px',
        width: '98%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    </Card>
  );
};
