import { Alert, AlertProps, Chip, Grid, TextField } from '@mui/material';
import {
  CustomPin,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { useEffect, useState } from 'react';
import usePrevious from '~/src/common/hooks/usePrevious';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

type TagsTabProps = {
  pin: CustomPin;
};

const PinTags = ({ pin }: TagsTabProps) => {
  const [tagInput, setTagInput] = useState('');
  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const selectedPinId = useDynamicMapStore(
    (state) => state.selectedCustomPinId
  );
  const previousPinId = usePrevious(selectedPinId);

  useEffect(() => {
    if (selectedPinId === previousPinId) return;

    setTagInput('');
  }, [selectedPinId, previousPinId, pin]);

  const alertMessagePin = useDynamicMapStore((state) => state.alertMessagePin);
  const alertSeverity = useDynamicMapStore((state) => state.alertSeverityPin);
  const setAlertMessagePin = useDynamicMapStore(
    (state) => state.setAlertMessagePin
  );
  const setAlertSeverity = useDynamicMapStore(
    (state) => state.setAlertSeverityPin
  );
  const clearAlert = () => setAlertMessagePin('');

  useEffect(() => {
    if (alertMessagePin) {
      const timeout = setTimeout(() => {
        setAlertMessagePin('');
      }, 1500);
      return () => clearTimeout(timeout);
    }
    return;
  }, [alertMessagePin, setAlertMessagePin]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4}>
          <TextField
            id="tag"
            label="Add Tag"
            variant="outlined"
            size="small"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            inputProps={{ maxLength: 30 }}
            onKeyDown={(keyEvent) => {
              const finalValue = tagInput.toLowerCase().trim();
              if (keyEvent.key === 'Enter' && finalValue.length > 0) {
                if (pin.tags.includes(finalValue)) {
                  setAlertMessagePin(
                    'A tag with this name already exists! Please choose another name.'
                  );
                  setAlertSeverity('error');
                } else {
                  try {
                    customPinMethods?.addTag(pin.id, finalValue);
                  } catch (error) {
                    setAlertMessagePin(
                      'An error occurred while adding the tag.'
                    );
                    setAlertSeverity('error');
                  }
                }
                setTagInput('');
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {pin.tags.map((tag) => (
          <Grid item key={tag}>
            <Chip
              size="small"
              label={tag}
              color="primary"
              onDelete={() => {
                customPinMethods?.removeTag(pin.id, tag);
              }}
            />
          </Grid>
        ))}
      </Grid>
      {alertMessagePin && (
        <Alert
          severity={alertSeverity as AlertProps['severity']}
          onClose={clearAlert}
          sx={{ margin: 2 }}
        >
          {alertMessagePin}
        </Alert>
      )}
    </>
  );
};

export default PinTags;
