const SonarIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.68847 6.62676L7.60194 3.52539L14.3862 6.62675L7.60192 9.79274L0.68847 6.62676Z"
        stroke="url(#paint0_radial_2198_965)"
        strokeWidth="0.568452"
      />
      <path
        d="M0.68847 9.61114L7.60194 6.50977L14.3862 9.61113L7.60192 12.7771L0.68847 9.61114Z"
        stroke="url(#paint1_radial_2198_965)"
        strokeWidth="0.568452"
      />
      <path
        d="M7.60309 6.12695L11.1559 7.76125L7.60309 9.53767L3.97921 7.83231L7.60309 6.12695Z"
        fill="white"
      />
      <path
        d="M7.71074 3.07129L11.6533 5.06087L7.71074 7.33468L3.48182 5.06087L7.71074 3.07129Z"
        fill="white"
      />
      <path
        d="M0.68847 3.5003L7.60194 0.398925L14.3862 3.50029L7.60192 6.66627L0.68847 3.5003Z"
        stroke="url(#paint2_radial_2198_965)"
        strokeWidth="0.568452"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2198_965"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.53199 6.65962) rotate(90) scale(3.44624 7.53199)"
        >
          <stop stopColor="#F5F5F5" />
          <stop offset="1" stopColor="#3604C2" stopOpacity="0.29" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2198_965"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.53199 9.644) rotate(90) scale(3.44624 7.53199)"
        >
          <stop stopColor="#F5F5F5" />
          <stop offset="1" stopColor="#3604C2" stopOpacity="0.29" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_2198_965"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.53199 3.53316) rotate(90) scale(3.44624 7.53199)"
        >
          <stop stopColor="#F5F5F5" />
          <stop offset="1" stopColor="#3604C2" stopOpacity="0.29" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default SonarIcon;
