import { AutoMode } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import OneSchemaImporter from '@oneschema/react';
import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { useState } from 'react';
import {
  mergePins,
  mergeTerritories,
} from '../../file-parsing/helpers/mergeFeatures';
import {
  createEmbedSession,
  uploadFileString,
} from '../../file-parsing/oneSchemaService';
import { FileResponse } from '../files-service';

const env = parseEnv({
  API_V2: process.env.API_V2,
});

// const parsingFunctionsMap: Record<string, (file: File) => Promise<string[][]>> =
//   {
//     'application/geo+json': parseGeoJSONToCsv,
//     'application/json': parseJSONToCsv,
//     'application/vnd.google-earth.kml+xml': parseKMLToCsv,
//     shp: parseSHPToCsv,
//   };

export const ProcessFileButton = ({ file }: { file: FileResponse }) => {
  const [open, setOpen] = useState(false);
  const [uploadType, setUploadType] = useState<'pins' | 'territories' | null>(
    'territories'
  );
  // const { openPortal } = useFlatfile();
  const [isOneSchemaOpen, setIsOneSchemaOpen] = useState(false);
  const [embedSession, setEmbedSession] = useState<{
    token: string;
    id: number;
    user_jwt: string;
  } | null>(null);

  const handleStartProcessing = async () => {
    const s3File = await axios.get(
      `${env.API_V2}/user-uploads/${file.id}/download`
    );

    const fileContent = s3File.data;

    if (!fileContent) return;

    if (file.fileType === 'csv') {
      const embedSession = await createEmbedSession(uploadType + '_template');
      if (!embedSession) return;

      const fileToParse = fileContent as string;
      const headers = fileToParse.split('\n')[0].split(';');
      const lines = fileToParse
        .split('\n')
        .slice(1)
        .map((line) => line.split(';'));

      await uploadFileString([headers, ...lines], embedSession.id);
      setIsOneSchemaOpen(() => {
        setEmbedSession(embedSession);
        return true;
      });
      return;
    }

    if (file.fileType === 'application/json') {
      const formatted: Record<string, string | number>[] = (
        fileContent as object[]
      ).map((feature: any) => ({
        ...feature.properties,
        geometry: feature.geometry,
      }));

      const csv = formatted.map((feature) =>
        Object.values(feature)
      ) as string[][];

      const headers = Object.keys(formatted[0]);

      const parsedFile = [headers, ...csv];

      const embedSession = await createEmbedSession(uploadType + '_template');
      if (!embedSession) return;

      await uploadFileString(parsedFile, embedSession.id);
      setIsOneSchemaOpen(() => {
        setEmbedSession(embedSession);
        return true;
      });
    }

    if (file.fileType === 'application/geo+json') {
      const features = (fileContent as string)
        .split('\n')
        .filter((line) => line.trim() !== '');
      const parsedFeatures = features.map((feature) => JSON.parse(feature));

      const formatted: Record<string, string | number>[] = parsedFeatures.map(
        (feature) => ({
          ...feature.properties,
          geometry: feature.geometry,
        })
      );

      const csv = formatted.map((feature) =>
        Object.values(feature)
      ) as string[][];

      const headers = Object.keys(formatted[0]);

      const parsedFile = [headers, ...csv];

      const embedSession = await createEmbedSession(uploadType + '_template');
      if (!embedSession) return;

      await uploadFileString(parsedFile, embedSession.id);
      setIsOneSchemaOpen(() => {
        setEmbedSession(embedSession);
        return true;
      });
    }

    // if (file.fileType === 'text/csv') {
    //   const embedSession = await createEmbedSession(uploadType + '_template');
    //   if (!embedSession) return;

    //   await uploadFile(fileContent, embedSession.id);
    //   setIsOneSchemaOpen(() => {
    //     setEmbedSession(embedSession);
    //     return true;
    //   });
    //   // openPortal();
    //   // setOpen(false);
    //   return;
    // }

    // if (file.fileType.endsWith('.shp')) {
    //   const parsingFunction = parsingFunctionsMap['shp'];
    //   if (!parsingFunction) return null;
    //   const parsedFile = await parsingFunction(fileContent);
    //   // openPortal();
    //   // setOpen(false);
    //   const embedSession = await createEmbedSession(uploadType + '_template');
    //   if (!embedSession) return;

    //   await uploadFileString(parsedFile, embedSession.id);
    //   setIsOneSchemaOpen(() => {
    //     setEmbedSession(embedSession);
    //     return true;
    //   });
    //   return;
    // }

    // const parsingFunction = parsingFunctionsMap[file.fileType];
    // if (!parsingFunction) return null;
    // const parsedFile = await parsingFunction(fileContent);

    // const embedSession = await createEmbedSession(uploadType + '_template');
    // if (!embedSession) return;

    // await uploadFileString(parsedFile, embedSession.id);
    // setIsOneSchemaOpen(() => {
    //   setEmbedSession(embedSession);
    //   return true;
    // });

    // openPortal();
    // setOpen()
  };

  return (
    <>
      <Tooltip title="Process file features on the map">
        <IconButton onClick={() => setOpen(true)}>
          <AutoMode />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Upload file (GeoJSON/JSON, SHP, CSV, KML)</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <Box display="flex" flexDirection="column" gap={2} overflow={'auto'}>
            {/* OneSchemaImporter will be showed on whole screen when file is processed */}
            {embedSession && (
              <OneSchemaImporter
                isOpen={isOneSchemaOpen}
                onCancel={() => {
                  setIsOneSchemaOpen(false);
                }}
                onSuccess={({ records }) => {
                  uploadType === 'territories' && mergeTerritories(records);
                  uploadType === 'pins' && mergePins(records);
                }}
                onError={(error) => {
                  console.error(error);
                  setOpen(false);
                }}
                onRequestClose={() => {
                  setOpen(false);
                }}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  zIndex: 1000,
                }}
                sessionToken={embedSession.token}
                clientId={'ed59799b-0cc0-42e4-95a4-27d2ced3b3fa'}
                templateKey={`${uploadType}_template`}
                userJwt="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJlZDU5Nzk5Yi0wY2MwLTQyZTQtOTVhNC0yN2QyY2VkM2IzZmEiLCJ1c2VyX2lkIjoiPFVTRVJfSUQ-In0.d-i6kA6lnUXNjNAsqpDfki8KD6WhIv7_CQMRY0O7PP8"
              />
            )}

            {/* Upload file block */}
            <FormControl sx={{ pl: 2 }}>
              <FormLabel id="upload-type-label">Data type</FormLabel>
              <RadioGroup
                aria-labelledby="upload-type-label"
                name="upload-type-radio-group"
                sx={{
                  flexDirection: 'row',
                }}
                onChange={(e) => {
                  setUploadType(e.target.value as 'pins' | 'territories');
                  handleStartProcessing();
                }}
              >
                <FormControlLabel
                  value="territories"
                  control={<Radio />}
                  label="Territories"
                />
                <FormControlLabel
                  value="pins"
                  control={<Radio />}
                  label="Pins"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
