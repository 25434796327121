import { Box, Typography } from '@mui/material';

interface MapColorLegendProps {
  data: {
    colors: string[];
    label: string;
  }[];
}

export const MapColorLegend = ({ data }: MapColorLegendProps) => {
  return (
    <Box display="flex" flexDirection="column">
      {data.map((item, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          gap={2}
          justifyContent="space-between"
        >
          <Box display="flex" gap={1}>
            {item.colors.map((colorHex) => (
              <span
                key={colorHex}
                style={{
                  backgroundColor: colorHex,
                  width: 12,
                  height: 12,
                  border: '1px solid #ddd',
                  borderRadius: '50%',
                }}
              />
            ))}
          </Box>

          <Typography textAlign="right" fontSize={12} color="gray">
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
