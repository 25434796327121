import { Box, Typography } from '@mui/material';
import { CustomPin, plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import {
  Rule,
  SourceType,
  TargetType,
} from '@plotr/plotr-multiplayer-data/src/lib/rulesets';
import type mapboxgl from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { Marker } from 'react-map-gl';
import getPinURL from '~/src/common/helpers/getPinURL';
import useDynamicMapStore, {
  MapStyle,
} from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useRulesets from '~/src/features/dynamic-map/hooks/useRulesets';
import { usePinsContextMenu } from './hooks/usePinsContextMenu';

const blackTextLightHalo = {
  color: 'black',
  textShadow: `
    1px 1px 0 white, 
    -1px -1px 0 white, 
    1px -1px 0 white, 
    -1px 1px 0 white,
    1px 0px 0 white,
    0px 1px 0 white,
    -1px 0px 0 white,
    0px -1px 0 white
  `,
};

const whiteTextDarkHalo = {
  color: 'white',
  textShadow: `
    1px 1px 0 black, 
    -1px -1px 0 black, 
    1px -1px 0 black, 
    -1px 1px 0 black,
    1px 0px 0 black,
    0px 1px 0 black,
    -1px 0px 0 black,
    0px -1px 0 black
  `,
};

export const getColor = (
  rulesets: Rule[],
  featureProperties: { [key: string]: string[] | string },
  tags: string[]
) => {
  const normalizedTags = tags.map((tag) => tag.toLowerCase());

  for (const rule of rulesets) {
    if (
      rule.sourceType === SourceType.KeyValue &&
      rule.propertyKey &&
      featureProperties[rule.propertyKey] === rule.evaluation.value
    ) {
      return rule.effect;
    }

    if (
      rule.sourceType === SourceType.Tag &&
      normalizedTags.includes(
        String(rule.evaluation.value).toLowerCase() as string
      )
    ) {
      return rule.effect;
    }
  }

  // Default color
  return null;
};

export default function CustomPinMarker({ pin }: { pin: CustomPin }) {
  const rules = useRulesets().filter(
    (rule) => rule.targetType === TargetType.Pins
  );

  const { selectCustomPinId } = useDynamicMapStore((state) => ({
    selectCustomPinId: state.selectCustomPinId,
  }));

  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const handleContextMenu = usePinsContextMenu(pin);

  const ref = useRef<mapboxgl.Marker>(null);

  // need to get at the underlying mapboxgl marker element to add the contextmenu event listener
  useEffect(() => {
    const node = ref.current;
    if (node != null) {
      node.getElement().addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (node != null) {
        node.getElement().removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, [ref, handleContextMenu]);

  const pinColor = getColor(rules, pin?.keyValuePairs ?? {}, pin?.tags ?? []);

  // TODO: add shadow beneath all pin images (see default <Marker /> for example)

  const pinURL = getPinURL({
    color: pinColor ?? '#3FB1CE',
    background: '#FFFFFF',
  });
  const zoom = useMapContext()?.getZoom();
  const minScale = 0.25; // Minimum scale for the pin
  const maxScale = 1; // Maximum scale for the pin
  const defaultIconSize = 1; // The initial size of the icon
  const exponentialIconRatio = 1 / 6; // The ratio for exponential growth
  const defaultZoomStops = [6, 16]; // Zoom levels at which to evaluate size

  const calculateScale = (zoom: number) => {
    if (!zoom) return defaultIconSize;

    const zoomDiff = zoom - defaultZoomStops[0];
    let scale;
    if (zoomDiff < 0) {
      scale = defaultIconSize * exponentialIconRatio;
    } else {
      const scaleRange =
        defaultIconSize - defaultIconSize * exponentialIconRatio;
      const step = scaleRange / (defaultZoomStops[1] - defaultZoomStops[0]);
      scale = defaultIconSize * exponentialIconRatio + step * zoomDiff;
    }

    // Clamp the scale between minScale and maxScale
    return Math.min(Math.max(scale, minScale), maxScale);
  };

  const scale = calculateScale(zoom ?? 1);

  const mapStyle = useDynamicMapStore((state) => state.mapStyle);
  const useWhiteText =
    mapStyle === MapStyle.Satellite || mapStyle === MapStyle.Dark;

  const mapStyleLoaded = useMapContext() != null;

  const pinLabelsEnabled = useDynamicMapStore(
    (state) => state.pinLabelsEnabled
  );

  // HACK: only render Markers when the map style is fully loaded
  return mapStyleLoaded && customPinMethods != null ? (
    <Marker
      ref={ref}
      key={pin.id}
      longitude={pin.pos.lng}
      latitude={pin.pos.lat}
      onClick={() => selectCustomPinId(pin.id)}
      anchor="bottom"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          transform: `scale(${scale})`,
          transformOrigin: 'center bottom',
        }}
      >
        <img
          style={{ width: '5rem', height: 'auto' }}
          src={pinURL}
          alt="custom pin"
        />
        <Typography
          variant="body1"
          sx={{
            ...(useWhiteText ? whiteTextDarkHalo : blackTextLightHalo),
            opacity: zoom ? (zoom <= 10 ? Math.max(0, (zoom - 5) / 5) : 1) : 1,
            transition: 'opacity 0.2s ease-in-out',
            marginTop: '0.25rem',
            textAlign: 'center',
            visibility: pinLabelsEnabled ? 'visible' : 'hidden',
            fontSize: '2rem',
          }}
        >
          {pin.label}
        </Typography>
      </Box>
    </Marker>
  ) : null;
}
