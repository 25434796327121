import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

interface KeyValueTableProps {
  data: Map<string, string>;
  addKeyValuePair: (key: string, value: string) => void;
  updateKeyValuePair: (key: string, value: string) => void;
  removeKeyValuePair: (key: string) => void;
  alertMessage?: (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error'
  ) => void;
}

const KeyValueTable: React.FC<KeyValueTableProps> = ({
  data,
  addKeyValuePair,
  updateKeyValuePair,
  removeKeyValuePair,
  alertMessage = () => {},
}) => {
  const [rows, setRows] = useState<Map<string, string>>(data);
  const [editingKey, setEditingKey] = useState<string>('');
  const [editingValue, setEditingValue] = useState<string>('');
  const [newKeyDuringEdit, setNewKeyDuringEdit] = useState<string>('');
  const [newKey, setNewKey] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');
  const keyInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const handleDelete = (key: string) => {
    removeKeyValuePair(key);
    alertMessage('Successfully removed key value pair.', 'warning');
    setRows((prevRows) => {
      const newRows = new Map(prevRows);
      newRows.delete(key);
      return newRows;
    });
  };

  const handleAddNewRow = () => {
    if (newKey && !newValue) {
      alertMessage(
        'Please add a value to save this key and value pairing.',
        'warning'
      );
      return;
    }
    if (newKey && newValue) {
      if (rows.has(newKey) && rows.get(newKey) === newValue) {
        alertMessage('This key-value pair already exists.', 'info');
        return;
      }
      if (rows.has(newKey) && rows.get(newKey) !== newValue) {
        alertMessage('Updated existing key with new value.', 'success');
      } else {
        alertMessage('Successfully added key value pair.', 'success');
      }
      addKeyValuePair(newKey, newValue);
      setRows((prevRows) => {
        const newRows = new Map(prevRows);
        newRows.set(newKey, newValue);
        return newRows;
      });
      setNewKey('');
      setNewValue('');
      keyInputRef.current?.focus();
    }
  };

  const startEditing = (key: string, value: string) => {
    setEditingKey(key);
    setEditingValue(value);
    setNewKeyDuringEdit(key);
  };

  const cancelEditing = () => {
    setEditingKey('');
    setEditingValue('');
    setNewKeyDuringEdit('');
  };

  const updateKeyValuePairHandler = () => {
    if (!editingValue) {
      alertMessage(
        'Please add a value to save this key and value pairing.',
        'warning'
      );
      return;
    }
    if (newKeyDuringEdit !== editingKey && rows.has(newKeyDuringEdit)) {
      alertMessage('Duplicate key detected. Please use a unique key.', 'error');
      return;
    }
    if (newKeyDuringEdit !== editingKey) {
      removeKeyValuePair(editingKey);
    }
    updateKeyValuePair(newKeyDuringEdit, editingValue);
    alertMessage('Successfully updated key value pair.', 'success');
    setRows((prevRows) => {
      const newRows = new Map(prevRows);
      newRows.delete(editingKey);
      newRows.set(newKeyDuringEdit, editingValue);
      return newRows;
    });
    setEditingKey('');
    setEditingValue('');
    setNewKeyDuringEdit('');
  };

  const handleKeyDown = (keyEvent: React.KeyboardEvent, isEditing: boolean) => {
    if (keyEvent.key === 'Enter') {
      if (isEditing) {
        updateKeyValuePairHandler();
      } else {
        handleAddNewRow();
      }
    }
  };

  return (
    <Box>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table stickyHeader aria-label="input table">
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  value={newKey}
                  onChange={(e) => setNewKey(e.target.value)}
                  size="small"
                  fullWidth
                  onKeyDown={(e) => handleKeyDown(e, false)}
                  inputRef={keyInputRef}
                  placeholder="Add Property"
                  InputProps={{ style: { fontSize: 12 } }}
                  aria-label="Add Property"
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                  size="small"
                  fullWidth
                  onKeyDown={(e) => handleKeyDown(e, false)}
                  placeholder="Add Value"
                  InputProps={{ style: { fontSize: 12 } }}
                  aria-label="Add Value"
                />
              </TableCell>
              <TableCell>
                <IconButton onClick={handleAddNewRow}>
                  <AddBoxIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', maxHeight: 300, overflowY: 'auto', marginTop: 2 }}
      >
        <Table stickyHeader aria-label="data table" sx={{ minHeight: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Key</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(rows.entries()).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  {editingKey === key ? (
                    <TextField
                      value={newKeyDuringEdit}
                      onChange={(e) => setNewKeyDuringEdit(e.target.value)}
                      size="small"
                      fullWidth
                      onKeyDown={(e) => handleKeyDown(e, true)}
                    />
                  ) : (
                    key
                  )}
                </TableCell>
                <TableCell>
                  {editingKey === key ? (
                    <TextField
                      value={editingValue}
                      onChange={(e) => setEditingValue(e.target.value)}
                      size="small"
                      fullWidth
                      onKeyDown={(e) => handleKeyDown(e, true)}
                    />
                  ) : (
                    value
                  )}
                </TableCell>
                <TableCell sx={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {editingKey === key ? (
                      <>
                        <IconButton onClick={updateKeyValuePairHandler}>
                          <CheckIcon />
                        </IconButton>
                        <IconButton onClick={cancelEditing}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton onClick={() => startEditing(key, value)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(key)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KeyValueTable;
