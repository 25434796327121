import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import useCustomTerritories from '~/src/features/dynamic-map/hooks/useCustomTerritories';
import { useCallback, useMemo } from 'react';
import {
  CustomPin,
  CustomTerritory,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import { getDemographicEntity } from '~/src/features/context-menu/helpers/getDemographicEntity';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type RemoveAssociationModalProps = {
  open: boolean;
  onClose: () => void;
  territoryId: string;
};

/*
    if (map && pin?.pos) {
      const convertedPos = map?.project(pin.pos);
      const features = map.queryRenderedFeatures(convertedPos, {});

      const territoriesUnderPin = features.reduce((prevValue, curValue) => {
        const { territory } = getDemographicEntity(curValue, customTerritories);

        return territory ? [...prevValue, territory] : prevValue;
      }, [] as CustomTerritory[]);

      return territoriesUnderPin.filter(
        (territory) => !pinTerritories.includes(territory)
      );
    }
*/

const RemoveAssociationModal = ({
  open,
  onClose,
  territoryId,
}: RemoveAssociationModalProps) => {
  const customTerritories = useCustomTerritories();
  const customPins = useCustomPins();
  const currentTerritory = customTerritories.find(
    (customTerritory) => customTerritory.id === territoryId
  );
  const map = useMapContext();

  // Do not render modal if we don't have pins to remove
  if (
    !currentTerritory ||
    !currentTerritory?.pins ||
    currentTerritory.pins.length < 1
  )
    onClose();

  const territoryPins = customPins.filter((pin) =>
    currentTerritory?.pins?.includes(pin.id)
  );

  const newPinsOnTerritory = useMemo(
    () =>
      territoryPins.filter((territoryPin) => {
        if (map && territoryPin?.pos) {
          const convertedPos = map?.project(territoryPin.pos);
          const features = map.queryRenderedFeatures(convertedPos, {});

          const passedFeatures = features.filter((feature) => {
            const { territory } = getDemographicEntity(
              feature,
              customTerritories
            );
            return !!territory;
          }, [] as CustomTerritory[]);

          return passedFeatures && passedFeatures.length > 0;
        }
        return false;
      }) ?? ([] as CustomPin[]),
    [map, territoryPins, customTerritories]
  );

  const pinsToRemove = territoryPins.filter(
    (territoryPin) => !newPinsOnTerritory.includes(territoryPin)
  );

  if (pinsToRemove.length < 1) onClose();

  const customTerritoriesMethods = plotrMultiplayerData.methods?.territories;

  const onSubmit = useCallback(() => {
    if (customTerritoriesMethods && currentTerritory) {
      customTerritoriesMethods.setPins(
        territoryId,
        newPinsOnTerritory.map((pin) => pin.id)
      );
    }
  }, [
    currentTerritory,
    customTerritoriesMethods,
    newPinsOnTerritory,
    territoryId,
  ]);

  return (
    <Modal
      aria-labelledby="Create template"
      aria-describedby="Create a new pin template"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} display={'flex'} flexDirection={'column'} gap={2}>
          <Typography variant="h6" component="h2">
            Do you want to remove the associations with the territory, that are
            out of it?
          </Typography>
          <Box>
            <Button onClick={onSubmit} fullWidth>
              Remove
            </Button>
            <Button onClick={onClose} fullWidth>
              Cancel
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default RemoveAssociationModal;
