import turfBuffer from '@turf/buffer';
import { Layer, Source } from 'react-map-gl';
import useLayerIds from '~/src/features/dynamic-map/hooks/useLayerIds';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useDemographicStore, { Boundary } from '../hooks/useDemographicStore';

export type Point = {
  lng: number;
  lat: number;
};

export type BoundaryType = 'radius' | 'driving' | 'walking' | 'cycling';

type MarkerAndBoundaryProps = {
  boundaryData: Boundary | null;
};

function MarkerAndBoundary({ boundaryData }: MarkerAndBoundaryProps) {
  const mapStyle = useDynamicMapStore((state) => state.mapStyle);
  const brandLayerIds = useLayerIds((layer) => layer.id.startsWith('poi-'));
  const { boundaryType } = useDemographicStore((state) => ({
    boundaryType: state.boundaryType,
  }));

  return boundaryData != null ? (
    <Source
      id="search-radius"
      type="geojson"
      data={
        boundaryType === 'radius'
          ? boundaryData
          : turfBuffer(boundaryData, 0.3, {
              units: 'kilometers',
            })
      }
    >
      <Layer
        id="search-radius-halo"
        type="line"
        paint={{
          'line-color': mapStyle === 'streets-v12' ? '#fff' : '#000',
          'line-opacity': mapStyle === 'streets-v12' ? 0.5 : 0.9,
          'line-width': mapStyle === 'streets-v12' ? 8 : 8,
        }}
        beforeId={brandLayerIds && brandLayerIds[0]}
      />
      <Layer
        id="search-radius"
        type="line"
        paint={{
          'line-color': mapStyle === 'streets-v12' ? '#000' : '#fff',
          'line-opacity': mapStyle === 'streets-v12' ? 0.5 : 0.9,
          'line-width': mapStyle === 'streets-v12' ? 4 : 4,
        }}
        beforeId={brandLayerIds && brandLayerIds[0]}
      />
    </Source>
  ) : null;
}

export default MarkerAndBoundary;
