import { Box, Paper, Typography } from '@mui/material';
import { FileResponse } from '../files-service';
import { DeleteFileButton } from './DeleteFileButton';
import { DownloadFileButton } from './DownloadFileButton';
import { ProcessFileButton } from './ProcessFileButton';

export const FileCard = ({ file }: { file: FileResponse }) => {
  return (
    <Paper variant="outlined" sx={{ width: '100%' }}>
      <Box padding={2} display="flex" flexDirection="column">
        <Typography variant="h6" gutterBottom>
          {file.s3Key}
        </Typography>
        <Typography variant="caption" gutterBottom>
          {file.s3Path.length > 50
            ? `${file.s3Path.substring(0, 50)}...`
            : file.s3Path}
        </Typography>
        <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'row'}>
          <ProcessFileButton file={file} />
          <DownloadFileButton file={file} />
          <DeleteFileButton file={file} />
        </Box>
      </Box>
    </Paper>
  );
};
