import { Box, Fade, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import {
  ModalNames,
  useModalDataContext,
} from '~/src/common/hooks/useModalDataContext';
import EditTemplateForm from './EditTemplate.form';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreateTemplateModal = () => {
  const { open, onOpenChange, data } = useModalDataContext();
  const isOpen = open.createTemplateModal;

  const onClose = () => {
    onOpenChange(ModalNames.CREATE_TEMPLATE, false);
  };

  return (
    <Modal
      aria-labelledby="Create template"
      aria-describedby="Create a new pin template"
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Typography id="Create template template" variant="h6" component="h2">
            Create new template
          </Typography>
          <EditTemplateForm
            template={data?.createTemplateModal?.template}
            closeForm={onClose}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateTemplateModal;
