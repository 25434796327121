import { create } from 'zustand';

export interface FacilitiesStore {
  facilities: string[];
  setFacilities: (facilities: string[]) => void;
}

const useFacilitiesStore = create<FacilitiesStore>((set) => ({
  facilities: [],
  setFacilities: (facilities: string[]) => set({ facilities }),
}));

export default useFacilitiesStore;
