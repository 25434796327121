import {
  CustomPin,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import React, { useEffect } from 'react';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import KeyValueTable from './KeyValueTable';
import { Alert, AlertProps } from '@mui/material';

interface CustomPinKVTableProps {
  pin: CustomPin;
}

const CustomPinKVTable: React.FC<CustomPinKVTableProps> = ({ pin }) => {
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const setAlertMessage = useDynamicMapStore(
    (state) => state.setAlertMessagePin
  );
  const setAlertSeverity = useDynamicMapStore(
    (state) => state.setAlertSeverityPin
  );
  const alertMessagePin = useDynamicMapStore((state) => state.alertMessagePin);
  const alertSeverity = useDynamicMapStore((state) => state.alertSeverityPin);
  const setAlertMessagePin = useDynamicMapStore(
    (state) => state.setAlertMessagePin
  );
  const clearAlert = () => setAlertMessagePin('');

  const alertMessage = (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error'
  ) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  useEffect(() => {
    if (alertMessagePin) {
      const timeout = setTimeout(() => {
        setAlertMessagePin('');
      }, 1500);
      return () => clearTimeout(timeout);
    }
    return;
  }, [alertMessagePin, setAlertMessagePin]);

  return (
    <>
      {' '}
      <KeyValueTable
        data={new Map(Object.entries(pin?.keyValuePairs || {}))}
        addKeyValuePair={(key, value) =>
          customPinMethods?.addKeyValuePair(pin.id, key, value)
        }
        updateKeyValuePair={(key, value) =>
          customPinMethods?.updateKeyValuePair(pin.id, key, value)
        }
        removeKeyValuePair={(key) =>
          customPinMethods?.removeKeyValuePair(pin.id, key)
        }
        alertMessage={alertMessage}
      />
      {alertMessagePin && (
        <Alert
          severity={alertSeverity as AlertProps['severity']}
          onClose={clearAlert}
          sx={{ margin: 2 }}
        >
          {alertMessagePin}
        </Alert>
      )}
    </>
  );
};

export default CustomPinKVTable;
