import { CustomPin } from '@plotr/plotr-multiplayer-data/src';
import CustomPinKVTable from '~/src/features/demographic-point-lookup/components/CustomPinKVTable';
import { Box } from '@mui/material';

type PropertiesTabProps = {
  pin: CustomPin;
};

const PropertiesTab = ({ pin }: PropertiesTabProps) => {
  const stopPropagation = (e: React.WheelEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Box onWheel={stopPropagation}>
      <CustomPinKVTable pin={pin} />
    </Box>
  );
};

export default PropertiesTab;
