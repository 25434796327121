import { Layer, Source } from 'react-map-gl';
import getPinURL from '~/src/common/helpers/getPinURL';
import useMapImage from '~/src/features/dynamic-map/hooks/useMapImage';
import useDynamicMapStore from '../hooks/useDynamicMapStore';
import MFHPulseSourceLayer from './MFHPulseSourceLayer';

export function MFHSource() {
  const completedMFHId = 'mfh-completed';
  const underConstructionMFHId = 'mfh-under-construction';

  const completedImageUrl =
    'https://plotr-public.s3.amazonaws.com/assets/images/building-emoji.png';
  const underConstructionImageUrl =
    'https://plotr-public.s3.amazonaws.com/assets/images/building-emoji.png';

  const completedMFHPinImage = getPinURL({
    color: '#21ff06',
    image: completedImageUrl,
    iconRatio: 0.7,
    crop: false,
  });

  const underConstructionMFHPinImage = getPinURL({
    color: '#ffff0a',
    image: underConstructionImageUrl,
    iconRatio: 0.75,
    crop: false,
  });

  const selectedMFH = useDynamicMapStore((state) => state.selectedMFH);
  const isPOiEnabled = useDynamicMapStore((state) => state.poisEnabled);

  const isCompletedMFHImageReady = useMapImage({
    id: completedMFHId,
    src: completedMFHPinImage,
  });

  const isUnderConstructionImageReady = useMapImage({
    id: 'mfh-under-construction',
    src: underConstructionMFHPinImage,
  });

  const currentYear = new Date().getFullYear();
  const decadeAgo = currentYear - 10;

  return (
    <Source
      id="multi_family"
      type="vector"
      url="mapbox://luketruitt1.multi_family"
    >
      <Layer
        id={completedMFHId}
        type="symbol"
        source="multi_family"
        source-layer="multi_family"
        minzoom={9.5}
        filter={[
          'all',
          ['<', ['get', 'year_built'], currentYear],
          ['>=', ['get', 'year_built'], decadeAgo],
        ]}
        layout={{
          visibility:
            selectedMFH.completed && isPOiEnabled ? 'visible' : 'none',
          ...(isCompletedMFHImageReady && { 'icon-image': completedMFHId }),
          'icon-anchor': 'bottom',
          'icon-allow-overlap': false,
          'icon-size': {
            type: 'exponential',
            stops: [
              [10, 0.1],
              [16, 0.3],
            ],
          },
          'text-field': ['get', 'name'],
          'text-allow-overlap': false,
          'text-anchor': 'top',
          'text-size': {
            type: 'exponential',
            stops: [
              [10, 12],
              [16, 16],
            ],
          },
        }}
        paint={{
          'text-halo-color': 'rgba(255, 255, 255, 0.5)',
          'text-halo-width': 2,
          'icon-opacity': {
            type: 'exponential',
            stops: [
              [10.5, 0],
              [11, 1],
            ],
          },
          'text-opacity': {
            type: 'exponential',
            stops: [
              [10.5, 0],
              [11, 1],
            ],
          },
        }}
      />
      <Layer
        id={underConstructionMFHId}
        type="symbol"
        source="multi_family"
        source-layer="multi_family"
        minzoom={9.5}
        filter={[
          'any',
          ['==', ['get', 'year_built'], null as any],
          ['>=', ['get', 'year_built'], currentYear],
        ]}
        layout={{
          visibility: selectedMFH.underConstruction ? 'visible' : 'none',
          ...(isUnderConstructionImageReady && {
            'icon-image': underConstructionMFHId,
          }),
          'icon-anchor': 'bottom',
          'icon-allow-overlap': false,
          'icon-size': {
            type: 'exponential',
            stops: [
              [10, 0.1],
              [16, 0.3],
            ],
          },
          'text-field': ['get', 'name'],
          'text-allow-overlap': false,
          'text-anchor': 'top',
          'text-size': {
            type: 'exponential',
            stops: [
              [10, 12],
              [16, 16],
            ],
          },
        }}
        paint={{
          'text-halo-color': 'rgba(255, 255, 255, 0.5)',
          'text-halo-width': 2,
          'icon-opacity': {
            type: 'exponential',
            stops: [
              [10.5, 0],
              [11, 1],
            ],
          },
          'text-opacity': {
            type: 'exponential',
            stops: [
              [10.5, 0],
              [11, 1],
            ],
          },
        }}
      />
      <>
        <MFHPulseSourceLayer
          mfhId={completedMFHId}
          mfhPinImage={completedMFHPinImage}
        />
        <MFHPulseSourceLayer
          mfhId={underConstructionMFHId}
          mfhPinImage={underConstructionMFHPinImage}
        />
      </>
    </Source>
  );
}
