import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';

interface AccordionSectionProps {
  title: string;
  children: React.ReactNode;
}

const accordionStyles = {
  marginTop: 1,
  boxShadow: 'none',
};

const accordionSummaryStyles = {
  border: '1px solid #ddd',
  borderRadius: '4px 4px 0 0',
  backgroundColor: '#00000008',
};

const accordionDetailsStyles = {
  border: '1px solid #ddd',
  borderRadius: '0 0 4px 4px',
  borderTop: 0,
};

const AccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  children,
}) => (
  <Accordion sx={accordionStyles} className="layers-accordion">
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={accordionSummaryStyles}
    >
      <Typography fontWeight={500}>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={accordionDetailsStyles}>
      <Box sx={{ overflow: 'auto' }}>{children}</Box>
    </AccordionDetails>
  </Accordion>
);

export default AccordionSection;
