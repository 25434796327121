import { useCallback, useState } from 'react';
import { MobileDataConfig } from '~/src/constants';
import { FetchTradeAreaOverlapDataResult } from '~/src/features/dynamic-map/services/poiService';

import { endOfMonth, startOfMonth, subDays, subMonths } from 'date-fns';

function getLastSixMonths() {
  const end = subDays(endOfMonth(subMonths(new Date(), 1)), 1);
  const start = startOfMonth(subMonths(end, 5));

  return {
    startDate: start.toISOString().split('T')[0],
    endDate: end.toISOString().split('T')[0],
  };
}

const useDateRangePicker = (
  tradeAreaOverlapData: FetchTradeAreaOverlapDataResult | null
) => {
  const { MIN_DATE: minDate, MAX_DATE: maxDate } = MobileDataConfig;

  const { startDate: initialStartDate, endDate: initialEndDate } =
    tradeAreaOverlapData
      ? {
          startDate: tradeAreaOverlapData.startDate,
          endDate: tradeAreaOverlapData.endDate,
        }
      : getLastSixMonths();

  const [startDate, setStartDate] = useState<string | null>(initialStartDate);
  const [endDate, setEndDate] = useState<string | null>(initialEndDate);

  const validateDates = useCallback(
    (start: string | null, end: string | null): boolean => {
      if (!start || !end) return false; // If either date is null, consider it invalid
      const startDate = new Date(start);
      const endDate = new Date(end);
      return startDate <= endDate;
    },
    []
  );

  const handleStartDateChange = useCallback(
    (newDate: Date | null) => {
      if (!newDate) {
        setStartDate(null); // Allow clearing the date
        return;
      }

      try {
        const year = newDate.getFullYear();
        const month = newDate.getMonth();

        const firstDayOfMonth = new Date(year, month, 1);
        const formattedStartDate = firstDayOfMonth.toISOString().split('T')[0];

        setStartDate(formattedStartDate);
      } catch (error: any) {
        console.error(error.message);
      }
    },
    [setStartDate]
  );

  const handleEndDateChange = useCallback(
    (newDate: Date | null) => {
      if (!newDate) {
        setEndDate(null);
        return;
      }

      try {
        const year = newDate.getFullYear();
        const month = newDate.getMonth();

        const lastDayOfMonth = new Date(year, month + 1, 0);

        // Clamp the end date to maxDate
        if (maxDate && lastDayOfMonth > new Date(maxDate)) {
          setEndDate(maxDate);
        } else {
          const formattedEndDate = lastDayOfMonth.toISOString().split('T')[0];
          setEndDate(formattedEndDate);
        }
      } catch (error: any) {
        console.error(error.message);
      }
    },
    [setEndDate, maxDate]
  );

  return {
    startDate,
    endDate,
    validateDates,
    handleStartDateChange,
    handleEndDateChange,
    minDate,
    maxDate,
  };
};

export default useDateRangePicker;
