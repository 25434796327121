import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import MarketRankDisplay from './MarketRankDisplay';
import NationalRankDisplay from './NationalRankDisplay';
import SonarDisplay from './SonarDisplay';
import RankingYearPicker from './RankingYearPicker';
import { HubspotModalType } from '../../HubspotModal';

const NoBrandDataSonarCard: React.FC = () => {
  const { poiData, sonarDataIsLoading, sonarDataYear, setSonarDataYear } =
    useDemographicStore((state) => ({
      poiData: state.poiData,
      sonarDataIsLoading: state.sonarDataIsLoading,
      sonarDataYear: state.sonarDataYear,
      setSonarDataYear: state.setSonarDataYear,
    }));

  const clickedPOIFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );

  const hasPermission = false;

  const { city, region } = clickedPOIFeature?.properties ?? {};
  const { cbsaName } = poiData ?? {};

  const getCBSAName = () => {
    if (cbsaName) {
      return `${cbsaName}`;
    }
    return `${city}, ${region}`;
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: 3,
        width: '90%',
        margin: 2,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
        },
      }}
    >
      {sonarDataIsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box position="relative">
          <Grid container spacing={2} justifyContent="center">
            <RankingYearPicker
              sonarDataYear={sonarDataYear}
              setSonarDataYear={setSonarDataYear}
            />
            <SonarDisplay
              hasPermission={hasPermission}
              contextString={HubspotModalType.SonarBrand}
            />
            <NationalRankDisplay
              hasPermission={hasPermission}
              clickedPOIFeature={null}
              contextString={HubspotModalType.SonarBrand}
            />
            <MarketRankDisplay
              hasPermission={hasPermission}
              getCBSAName={getCBSAName}
              contextString={HubspotModalType.SonarBrand}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default NoBrandDataSonarCard;
