import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDataConfig } from '~/src/constants';

interface RankingYearPickerProps {
  sonarDataYear: number;
  setSonarDataYear: (year: number) => void;
}

function RankingYearPicker({
  sonarDataYear,
  setSonarDataYear,
}: RankingYearPickerProps) {
  const { MIN_TREND_YEAR, MAX_RANKING_YEAR } = MobileDataConfig;

  return (
    <Grid item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year']}
          label="Year"
          value={new Date(sonarDataYear, 0)}
          onChange={(newValue) => {
            if (newValue) {
              setSonarDataYear(newValue.getFullYear());
            }
          }}
          minDate={new Date(Number(MIN_TREND_YEAR), 0)}
          maxDate={new Date(Number(MAX_RANKING_YEAR), 0)}
          sx={{ width: '100%' }}
        />
      </LocalizationProvider>
    </Grid>
  );
}

export default RankingYearPicker;
