import { ReactNode } from 'react';

export interface CommonHoneycombProps<> {
  size: number;
  items: Array<{ title?: string; value?: string | number; bgColor?: string }>;
  renderItem(
    item: { title?: string; value?: string | number; bgColor?: string },
    index: number
  ): React.ReactElement;
  columns: number;
  className?: string;
  gap?: number;
}

export interface HoneycombCellProps {
  row: number;
  column: number;
  children: ReactNode;
}

export interface HexagonProps {
  className?: string;
  style?: React.CSSProperties;
  gap?: number;
  children: ReactNode;
}

export function getGridColumnsCount(
  hexagonSide: number,
  containerWidth: number
): number {
  const hexagonWidth = Math.sqrt(3) * hexagonSide;
  const columns = Math.floor(containerWidth / hexagonWidth);
  return columns;
}

export function getRowSize(hexagonSide: number): number {
  return hexagonSide / 2;
}

export function getColumnSize(hexagonSide: number): number {
  return (Math.sqrt(3) * hexagonSide) / 3.5;
}

export const Hexagon = ({
  children,
  className,
  gap = 1,
  style = {},
}: HexagonProps) => {
  const clipPath = `polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)`;
  return (
    <div
      className={className}
      style={{
        ...style,
        position: 'absolute',
        top: gap / 2,
        left: gap / 2,
        right: gap / 2,
        bottom: gap / 2,
        clipPath,
        pointerEvents: 'auto',
      }}
    >
      {children}
    </div>
  );
};

const HoneycombCell = ({ children, row, column }: HoneycombCellProps) => {
  const transform = row % 2 ? `translateX(50%)` : `translateX(0%)`;
  return (
    <li
      style={{
        gridRow: `${row} / span 4`,
        gridColumn: `${column} / span 4`,
        pointerEvents: 'none',
        transform,
      }}
    >
      {children}
    </li>
  );
};

export const Honeycomb = ({
  items,
  renderItem,
  size,
  columns,
  className,
}: CommonHoneycombProps) => {
  const rowSize = getRowSize(size);
  const columnSize = getColumnSize(size);

  return (
    <ul
      className={className}
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns * 4}, ${columnSize}px)`,
        justifyContent: 'center',
        gridAutoRows: `${rowSize}px`,
        padding: `0 ${columnSize}px`,
        listStyle: 'none',
      }}
    >
      {items.map((item, index) => {
        const row = 1 + Math.floor(index / columns) * 3;
        const column = 1 + (index % columns) * 4;
        const renderedItem = renderItem(item, index);

        return (
          <HoneycombCell key={index} row={row} column={column}>
            {renderedItem}
          </HoneycombCell>
        );
      })}
    </ul>
  );
};
