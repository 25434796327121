import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Expression } from 'mapbox-expression';
import React, { useEffect, useMemo, useState } from 'react';
import { MapGeoJSONFeature, MapMouseEvent } from 'react-map-gl';
import usePrevious from '~/src/common/hooks/usePrevious';
import { kidstrongCustomerFacilities } from '~/src/global/constants/kidstrongCustomersLocation';
import useFacilitiesStore from '../dynamic-map/hooks/useFacilitiesStore';
import useLayersStore, {
  DataFieldConfig,
  LayerCard,
  formatValue,
} from '../dynamic-map/hooks/useLayersStore';
import useMapContext from '../dynamic-map/hooks/useMapContext';

interface HoverInfo {
  id: string | number;
  data: MapGeoJSONFeature;
  layerId: string;
  topLayer: LayerCard;
}

interface HoveredFeatureState {
  id: string | number;
  source: string;
  sourceLayer: string;
}

interface FeatureData {
  id: string | number;
  [key: string]: string | number;
}

const DataLayerHovercard: React.FC = () => {
  const showData = useLayersStore((state) => state.showData);
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null);
  const [persistentHoverInfo, setPersistentHoverInfo] =
    useState<HoverInfo | null>(null);
  const [hoveredState, setHoveredState] = useState<HoveredFeatureState | null>(
    null
  );
  const prevHoveredState = usePrevious(hoveredState);
  const facilities = useFacilitiesStore((state) => state.facilities);

  const map = useMapContext();
  const mapLayers = useLayersStore((state) => state.layers);

  const getMapboxFeatureAtGivenPoint = (e: MapMouseEvent) => {
    if (map == null) return;
    const allLayerIds = map.getStyle().layers.map((layer) => layer.id);

    const layerIdSet = new Set(allLayerIds);

    const queryLayers = mapLayers
      .flatMap((layer) => {
        const layersToAdd = [];
        if (layerIdSet.has(layer.id)) {
          layersToAdd.push(layer.id);
        }
        layersToAdd.push(
          ...allLayerIds.filter((id) => id.startsWith(layer.id + '-'))
        );
        return layersToAdd;
      })
      .filter((id) => id !== 'traffic_volume');

    const features = map.queryRenderedFeatures(e.point, {
      layers: queryLayers,
    });

    // Find the first non-companion feature
    let i = 0;
    let feature = features[i];
    while (feature?.layer.id.includes('companion')) {
      i += 1;
      feature = features[i];
    }

    return feature;
  };

  useEffect(() => {
    if (map == null) return;

    const mouseClickHandler = (e: MapMouseEvent) => {
      if (map == null) return;

      // get the feature that was clicked
      const feature = getMapboxFeatureAtGivenPoint(e);

      if (persistentHoverInfo) {
        setPersistentHoverInfo(null);
      } else if (hoverInfo) {
        // if the feature is not from the city-lifestyle-places layer, do nothing
        if (feature?.sourceLayer !== 'cities') return;
        setPersistentHoverInfo(hoverInfo);
      }
    };

    const mousemoveHandler = (e: MapMouseEvent) => {
      if (map == null || !showData) return;

      const feature = getMapboxFeatureAtGivenPoint(e);

      if (
        feature != null &&
        typeof feature.id !== 'undefined' &&
        !persistentHoverInfo
      ) {
        const formattedId = feature.id.toString();
        const layerId = feature.layer.id;

        setHoveredState({
          id: feature.id,
          source: feature.source,
          sourceLayer: feature.sourceLayer,
        });

        const topLayer = mapLayers.find(
          (layer) => layer.id === layerId || layerId.startsWith(layer.id)
        );
        if (!topLayer) return;

        setHoverInfo({
          id: formattedId,
          data: feature,
          layerId: layerId,
          topLayer,
        });
      } else {
        if (!persistentHoverInfo) {
          setHoverInfo(null);
        }
      }
    };

    const mouseoutHandler = () => {
      if (!showData || persistentHoverInfo) return;
      setHoverInfo(null);
      setHoveredState(null);
    };

    map.on('mousemove', mousemoveHandler);
    map.on('mouseout', mouseoutHandler);
    map.on('click', mouseClickHandler);

    return () => {
      map.off('mousemove', mousemoveHandler);
      map.off('mouseout', mouseoutHandler);
      map.off('click', mouseClickHandler);
    };
  }, [map, mapLayers, showData, hoverInfo, persistentHoverInfo]);

  useEffect(() => {
    if (map == null || showData == null) return;

    if (prevHoveredState != null) {
      map.setFeatureState(prevHoveredState, { hover: false });
    }

    if (hoveredState != null) {
      map.setFeatureState(hoveredState, { hover: true });
    }
  }, [map, hoveredState, prevHoveredState, showData]);

  const activeLayer = hoverInfo?.topLayer || persistentHoverInfo?.topLayer;
  const dataConfig = activeLayer?.dataConfig;

  const featureData: FeatureData | null = useMemo(() => {
    if (hoverInfo == null && persistentHoverInfo == null) return null;
    const info = persistentHoverInfo || hoverInfo;

    if (info == null) return null;
    return {
      ...{ id: info?.id },
      ...info?.data.properties,
    };
  }, [hoverInfo, persistentHoverInfo]);

  const displayData = useMemo(() => {
    if (activeLayer == null || dataConfig == null || featureData == null) {
      return {};
    }

    const formattedDataConfig =
      activeLayer.id === 'kidstrong_customer_heatmap_v2'
        ? {
            ...dataConfig,
            fields: [
              ...(dataConfig?.fields || []),
              ...(facilities.length > 0
                ? facilities
                : kidstrongCustomerFacilities
              ).map((facility) => ({
                [facility]: {
                  expression: ['get', facility.toLowerCase() + '_ks_cus'],
                  removeNullable: true,
                },
              })),
            ] as Array<{
              [Field: string]: string | DataFieldConfig;
            }>,
          }
        : dataConfig;

    return (formattedDataConfig.fields ?? []).reduce<{
      [key: string]: string | string[];
    }>((acc, field) => {
      const fieldKey = Object.keys(field)[0];
      const dataKey = field[fieldKey];

      if (typeof dataKey === 'string') {
        acc[fieldKey] = formatValue(featureData[dataKey]) ?? 'N/A';
      } else {
        if ('expression' in dataKey) {
          try {
            let fieldName = fieldKey;

            if (dataKey.fieldName) {
              fieldName =
                typeof dataKey.fieldName === 'string'
                  ? dataKey.fieldName
                  : Expression.parse(dataKey.fieldName).evaluate({
                      properties: featureData,
                    });
            }

            const expression = Expression.parse(dataKey.expression);

            const value = expression.evaluate({ properties: featureData });

            const formattedValue =
              formatValue(value ?? dataKey.default, dataKey.format) ?? 'N/A';

            if (
              !dataKey.removeNullable ||
              (dataKey.removeNullable && formattedValue !== 'N/A')
            ) {
              acc[fieldName] = formattedValue;
            }
          } catch (e) {
            console.error(e);
            acc[fieldKey] = dataKey.default
              ? formatValue(dataKey.default)
              : 'N/A';
          }
        } else {
          const value = featureData[dataKey.key];
          acc[fieldKey] =
            formatValue(value, dataKey.format) ?? dataKey.default ?? 'N/A';
        }

        dataKey.removeNullable &&
          acc[fieldKey] === 'N/A' &&
          delete acc[fieldKey];
      }
      return acc;
    }, {});
  }, [activeLayer, dataConfig, featureData]);

  if (!showData || (featureData == null && dataConfig == null)) return null;

  if (featureData && dataConfig == null) {
    return (
      <Box position="absolute" top="4rem" left="1rem" minWidth="16rem">
        <Card raised>
          {featureData != null && (
            <CardHeader title={featureData.id} sx={{ padding: 2 }} />
          )}
        </Card>
      </Box>
    );
  }

  function getFieldFormat(key: string) {
    const field = dataConfig?.fields?.find((f) => Object.keys(f)[0] === key);
    if (field == null) return null;

    const fieldConfig = field[Object.keys(field)[0]];

    if (typeof fieldConfig === 'string') {
      return null;
    }

    return fieldConfig.format;
  }

  return (
    <Box
      position="absolute"
      top="4rem"
      left="1rem"
      minWidth="16rem"
      maxWidth="26rem"
    >
      <Card
        raised
        sx={{
          maxHeight: '80vh',
          overflow: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {featureData != null && dataConfig?.header != null && (
            <CardHeader
              title={
                typeof dataConfig.header === 'string'
                  ? featureData[dataConfig.header]
                  : Array.isArray(dataConfig.header)
                    ? Expression.parse(dataConfig.header).evaluate({
                        properties: featureData,
                      })
                    : 'N/A'
              }
              sx={{ paddingBottom: 0 }}
            />
          )}
          {featureData != null && dataConfig?.caption != null && (
            <Typography variant="caption" color="text.secondary">
              {featureData[dataConfig.caption]}
            </Typography>
          )}
        </Box>
        <CardContent>
          <Table size="small">
            <TableBody>
              {Object.entries(displayData).map(([key, value]) => {
                const isCompactChips =
                  getFieldFormat(key) === 'chips' && value.length > 5;

                return (
                  <TableRow
                    key={key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {!isCompactChips && (
                      <TableCell sx={{ fontWeight: 700 }}>{key}</TableCell>
                    )}
                    <TableCell align={'right'} colSpan={isCompactChips ? 2 : 1}>
                      {isCompactChips && (
                        <Typography align="center" p={1} fontWeight={700}>
                          {key}
                        </Typography>
                      )}
                      {Array.isArray(value) &&
                      getFieldFormat(key) === 'chips' ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          {value.sort().map((item, index) => (
                            <Chip
                              key={index}
                              label={item}
                              sx={{ margin: '0.25rem' }}
                            />
                          ))}
                        </Box>
                      ) : (
                        value
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DataLayerHovercard;
