import { parseEnv } from '@plotr/common-utils/src';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Boundary } from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';

const PSYCHOGRAPHIC_DATA_QUERY_KEY = 'psychographicData';

export type PsychographicData = {
  id: number;
  name: string;
  description: string;
  count: number;
  parentColor: string;
  ownColor: string;
}[];

const { API_V2: API_V2_URL } = parseEnv({
  API_V2: process.env.API_V2,
});
export const getPsychographicData = async (boundaryData: Boundary | null) => {
  const response = await axios.post<PsychographicData>(
    `${API_V2_URL}/lifestyle/get-boundary-profiles`,
    {
      boundary: boundaryData,
      limit: 10,
    }
  );

  return response.data;
};

export const usePsychographicSearch = (boundaryData: Boundary | null) => {
  return useQuery({
    queryKey: [PSYCHOGRAPHIC_DATA_QUERY_KEY, boundaryData],
    queryFn: () => getPsychographicData(boundaryData),
    enabled: !!boundaryData,
    refetchOnWindowFocus: false,
  });
};
