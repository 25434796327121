import { z } from 'zod';

export function numberInputSchema({
  min,
  max,
  step,
}: {
  min: number;
  max: number;
  step: number;
}) {
  return z.object({
    radius: z.preprocess(
      (value) => {
        if (value === '' || value === undefined) return undefined;
        return typeof value === 'string' ? parseFloat(value) : value;
      },
      z
        .number({ invalid_type_error: 'Must be a number' })
        .min(min, `Value must be at least ${min}`)
        .max(max, `Value must be at most ${max}`)
        .superRefine((value, ctx) => {
          const isError = value % step !== 0;

          if (isError) {
            return ctx.addIssue({
              path: [],
              code: z.ZodIssueCode.custom,
              message: `Value must be a multiple of ${step}`,
              fatal: true,
            });
          }
        })
    ),
  });
}

export type NumberInputSchema = z.infer<ReturnType<typeof numberInputSchema>>;
