import { cloneElement, MouseEvent, ReactNode, useState } from 'react';
import { Box, Popover } from '@mui/material';

interface ContentPopoverProps {
  parentContainerClass: string;
  triggerElement: JSX.Element;
  children: ReactNode;
}

const ContentPopover = (props: ContentPopoverProps) => {
  const { parentContainerClass = '', triggerElement, children } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box className={parentContainerClass}>
      {cloneElement(triggerElement, {
        'aria-describedby': id,
        onClick: handleClick,
      })}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};

export default ContentPopover;
