import {
  LayerConfig,
  LayerType,
} from '../../features/dynamic-map-controller/components/MapLayersMenu/types';
import { Color } from './colors';

function generateQuarters(startDate: Date, endDate: Date): string[] {
  const quarters = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const quarter = Math.floor(currentDate.getMonth() / 3) + 1;
    quarters.unshift(`${year}_Q${quarter}`);
    currentDate.setMonth(currentDate.getMonth() + 3);
  }

  return quarters;
}

const quarters = generateQuarters(new Date(2007, 0, 1), new Date(2024, 6, 1));

const incomeBrackets = [
  {
    id: 'hist_inc_a_0k_50k',
    label: 'Under $50k',
    key: 'under_50k',
    thresholds: [0, 900, 1800, 2700, 3200, 3665],
  },
  {
    id: 'hist_inc_b_50k_75k',
    label: '$50k-$75k',
    key: '50k_to_75k',
    thresholds: [0, 300, 600, 900, 1200, 1523],
  },
  {
    id: 'hist_inc_c_75k_100k',
    label: '$75k-$100k',
    key: '75k_to_100k',
    thresholds: [0, 150, 300, 450, 600, 741],
  },
  {
    id: 'hist_inc_d_100k_150k',
    label: '$100k-$150k',
    key: '100k_to_150k',
    thresholds: [0, 185, 370, 555, 740, 925],
  },
  {
    id: 'hist_inc_e_150k_200k',
    label: '$150k-$200k',
    key: '150k_to_200k',
    thresholds: [0, 194, 388, 582, 776, 971],
  },
  {
    id: 'hist_inc_f_200k_plus',
    label: 'Over $200k',
    key: 'over_200k',
    thresholds: [0, 100, 200, 300, 400, 500],
  },
];

const historicalIncomeLayers: Record<string, LayerConfig> =
  incomeBrackets.reduce(
    (bracketAcc, bracket) => {
      quarters.forEach((quarter) => {
        const [year, q] = quarter.split('_');

        bracketAcc[`hist_inc_${bracket.id}_${year}_${q}`] = {
          type: LayerType.Insights,
          featureType: 'polygon',
          displayName: `${year} ${q} - ${bracket.label} Households`,
          mergeGroup: `hist_inc_${bracket.id}`,
          category: 'Population.Income',
          groupName: 'demographic_layers',
          class: 'income',
          sourceTileset: {
            label: 'Block Group Historical Income',
            source: 'luketruitt1.bg_historical_income',
            source_layer: 'bg_historical_income',
          },
          styleConfig: {
            threshold: bracket.thresholds,
            colors: [
              Color.transparent,
              Color.yellow,
              Color.yellowOrange,
              Color.orange,
              Color.darkOrange,
              Color.darkRed,
            ],
            fallback: 0,
            expression: ['get', `${bracket.key}_${quarter}`],
            opacityStops: [1, 1, 1, 1, 1, 1],
            unit: 'households',
          },
          dataConfig: {
            header: 'geoid',
            fields: [
              {
                [`${bracket.label} Households`]: {
                  key: `${bracket.key}_${quarter}`,
                  format: 'integer',
                },
              },
            ],
          },
          permission: 'read:historical_income',
        };
      });

      return bracketAcc;
    },
    {} as Record<string, LayerConfig>
  );

export default historicalIncomeLayers;
