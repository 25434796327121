import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Box,
  Card,
  Collapse,
  Divider,
  SxProps,
  Typography,
} from '@mui/material';
import { useState } from 'react';

type CollapseCardProps = {
  children: React.ReactNode;
  title: string;
  titleStyles?: SxProps;
  containerStyles?: SxProps;
  collapseWrapperStyles?: SxProps;
  onClose?: () => void;
};

const CollapseCard = ({
  children,
  title,
  titleStyles,
  containerStyles,
  collapseWrapperStyles,
  onClose,
}: CollapseCardProps) => {
  const [open, setOpen] = useState(false);

  const handleChangeOpen = (value: boolean) => {
    setOpen(value);
    onClose?.();
  };

  return (
    <Card sx={{ width: '100%', minHeight: '4rem' }} variant="outlined">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '8px',
          '&:hover': { backgroundColor: '#f0f0f0', cursor: 'pointer' },
          ...containerStyles,
        }}
        onClick={() => handleChangeOpen(!open)}
      >
        <Divider textAlign="center">
          <ExpandMoreIcon
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          />
        </Divider>
        <Typography sx={titleStyles}>{title}</Typography>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          maxHeight: '30rem',
          ...(collapseWrapperStyles ?? {}),
        }}
      >
        <Collapse in={open}>{children}</Collapse>
      </Box>
    </Card>
  );
};
export default CollapseCard;
