import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useRef, useState } from 'react';
import { UploadFileBlock } from './UploadFileBlock';
import { useUploadUserFile } from './files-service';

const UploadFilesButton = () => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { mutate: uploadFiles, isError, isLoading } = useUploadUserFile();

  const handleFileChange = async (files: FileList | null) => {
    // TODO: Validate files here before uploading

    // Also, we can add toaster to notify user
    // about the upload status or some errors across the app

    if (files) {
      uploadFiles(files);
      !isError && setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        disabled={isLoading}
      >
        {isLoading ? 'Uploading...' : 'Upload Files'}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Upload Files</DialogTitle>
        <DialogContent>
          <UploadFileBlock
            handleFileChange={handleFileChange}
            inputRef={inputRef}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadFilesButton;
